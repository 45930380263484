&nbsp;
<!-- ****************************************************** 
*** Information for next 5 reservations and quick reservation***
****************************************************** -->
<div>
    <form [formGroup]="availableSlotForm">
        <div class="card">
            <div class="card-header">
                <h3 class="mb-0 title-position" translate>technicalInspection.informationAboutReservations</h3>
            </div>
            <div class="card-body">
                <div class="form-group form-row">
                    <!-- see reservation details button -->
                    <div class="form-group">
                        <button class="btn btn-outline-success ml-2" type="button" routerLink="/list-technical-inspections" *ngIf="perms.hasAccess(perms.CAN_SEARCH_RESERVATIONS)" translate>button.searchDetailsForReservations</button>
                    </div>
                    <!-- table for future reservations and possibility to create one quick  -->
                    <table class="table table-bordered table-striped w-100 center" style=" font-size: 19px">
                        <thead>
                            <tr>
                                <th class="text-center" translate>technicalInspection.date</th>
                                <th class="text-center" translate>technicalInspection.lineNumber</th>
                                <th class="text-center" translate>technicalInspection.regNumber</th>
                                <th class="text-center" translate>technicalInspection.phoneNumber</th>
                                <th class="text-center" translate>technicalInspection.category</th>
                                <th class="text-center" translate>technicalInspection.basicService</th>
                                <th class="text-center" translate>technicalInspection.slot</th>
                                <th class="text-center" translate>actions</th>
                            </tr>
                        </thead>
                        <tbody>
                            <!-- table data for future reservations -->
                            <tr *ngFor="let fti of futureTechInspections; let i = index">
                                <td class="text-center">{{fti.inspectionDate}}</td>
                                <td class="text-center">{{fti.techInspectionTimes![0].technicalPointWorkingHours!.line.number}}</td>
                                <td class="text-center">{{fti.regNumber}}</td>
                                <td class="text-center">{{fti.phoneNumber}}</td>
                                <td class="text-center">{{fti.rvsCategory!.description}}</td>
                                <td class="text-center">{{fti.services}}</td>
                                <td class="text-center">{{fti.slot}}</td>
                                <td class="text-center">
                                    <button type="button" *ngIf="isStartedTechInsp && i == 0" disabled="true" class="btn btn-sm btn-primary" translate>button.moved</button>
                                    <button type="button" *ngIf="showLateButton && i == 0 && !isStartedTechInsp" (click)="changeTechInspStatus(fti.id!, 'LATED')" class="btn btn-sm btn-primary" translate>button.late</button>
                                    <button type="button" *ngIf="showStartButton && i == 0 && !isStartedTechInsp" (click)="startTechInspection(fti)" class="btn btn-sm btn-primary ml-1" title="{{'messagebox.moveTechInspData' | translate}}" translate>button.fill</button>
                                    <button type="button" *ngIf="!showStartButton && i == 0 && !isStartedTechInsp" (click)="onSubmitAdditionalFormAnnulModalFutureReservation(fti.id!)" class="btn btn-sm btn-danger" translate>button.annul</button>
                                    <!-- <button type="button" *ngIf="i != 0" (click)="changeTechInspStatus(fti.id!, 'ANNULLED')" class="btn btn-sm btn-danger" translate>button.annul</button> -->
                                    <button type="button" *ngIf="i != 0" (click)="onSubmitAdditionalFormAnnulModalFutureReservation(fti.id!)" class="btn btn-sm btn-danger" translate>button.annul</button>
                                </td>
                            </tr>
                            <br>
                            <!-- Create quick reservation -->
                            <tr class="individual_border">
                                <!-- date -->
                                <td class="text-center">
                                    <div class="input-group mt-1"> 
                                        <input class="form-control d-inline" [minDate]="minDate" style="max-width: 250pt;" aria-describedby="forDate"  formControlName="forDate" 
                                            ngbDatepicker #d1="ngbDatepicker" readonly (dateSelect)='onAvailableSlotDataChange()'>
                                        <div class="input-group-append">
                                            <button class="btn btn-outline-secondary fa fa-calendar" (click)="d1.toggle()" type="button"></button>
                                        </div>
                                    </div>
                                </td>

                                <!-- line -->
                                <td class="text-center">
                                    <select class="custom-select input-custom-size" formControlName="line" [compareWith]="compareById" 
                                            (change)='onAvailableSlotDataChange()'>
                                        <option *ngFor="let line of technicalPointLines" [ngValue]="line">
                                            {{line.number}}
                                        </option>
                                    </select>
                                </td>
                                
                                <!-- reg number -->
                                <td class="text-center">
                                    <input type="text" formControlName="regNumber" class="form-control input-custom-size" placeholder="{{'placeholder.enter' | translate}}"
                                        (focusout)="normalizeRegNumber('slotForm', availableForm.regNumber.value)"
                                        (input)="validateAvailableSlotFormRegNumber(availableForm.regNumber.value)"
                                        [ngClass]="{ 'is-invalid': submittedAvailableSlotForm && availableForm.regNumber.errors || isValidSlotFormRegNum }">
                                    <div class="text-danger" *ngIf="isValidSlotFormRegNum"><small translate>errors.onlyLatinSymbolsAndDigits</small></div>
                                    <ng-container *ngIf="slotFormValidation">
                                        <div class="text-danger" *ngIf="isInvalidRegNumberForForeigner"><small translate>errors.regNumberForForeigner</small></div>
                                        <div class="text-danger" *ngIf="isInvalidRegNumberForDiplomatic"><small translate>errors.regNumberForDiplomatic</small></div>
                                        <div class="text-danger" *ngIf="isInvalidRegNumberForElectric"><small translate>errors.regNumberForElectric</small></div>
                                    </ng-container>
                                    <div class="invalid-feedback" *ngIf="availableForm.regNumber.errors?.required" translate>errors.required</div>
                                    <div class="invalid-feedback" *ngIf="availableForm.regNumber.errors?.maxlength" translate>errors.tooLong</div>
                                </td>

                                <!-- phone number -->
                                <td class="text-center">
                                    <input type="text" formControlName="phoneNumber" class="form-control input-custom-size" placeholder="{{'placeholder.enter' | translate}}"
                                    [ngClass]="{ 'is-invalid': submittedAvailableSlotForm && availableForm.phoneNumber.errors }">  
                                    <div class="invalid-feedback" *ngIf="availableForm.phoneNumber?.errors?.maxlength" translate>errors.tooLong</div>
                                    <div class="invalid-feedback" *ngIf="availableForm.phoneNumber?.errors?.invalidPhoneNumber" translate>errors.phoneInvalid</div>
                                </td>

                                <!-- category -->
                                <td class="text-center">
                                    <select class="custom-select input-custom-size" formControlName="rvsCategory" [compareWith]="compareById" 
                                            (change)='onAvailableSlotRvsCategoryChange()'>
                                        <option *ngFor="let category of rvsCategories" [ngValue]="category">
                                            {{category.description}}
                                        </option>
                                    </select>
                                </td>

                                <!-- basic tech point service -->
                                <td class="text-center">
                                    <select class="custom-select input-custom-size" formControlName="basicTechPointService" [compareWith]="compareById"
                                            (change)='onAvailableSlotDataChange()'>
                                        <option *ngFor="let item of basicServicesForFirstAvailableSlot" [ngValue]="item">
                                            {{item.serviceName}}
                                        </option>
                                    </select>
                                </td>
                                
                                <!-- slots -->
                                <td class="text-center">
                                    <div class="d-flex inline">
                                        <label *ngIf="!groupedTechPointWorkingHours?.length" translate>technicalInspection.noAvailableSlots</label>
                                        <select *ngIf="groupedTechPointWorkingHours?.length" class="custom-select input-custom-size" formControlName="groupedTechPointWorkingHours">
                                            <option *ngFor="let gtpwk of groupedTechPointWorkingHours" [ngValue]="gtpwk">
                                                {{gtpwk.slot}}
                                            </option>
                                        </select>
                                        <button 
                                            [ngClass]="{'clicked': isPastClicked}" 
                                            class="btn btn-success btn-sm ml-1 mt-1 rotate" 
                                            style="height: 36px; width: 30px" 
                                            (click)="loadOldReservations(arePastLoaded, isPastClicked)" translate>
                                            <i class="fa fa-refresh"></i>
                                        </button>
                                    </div>
                                </td>

                                <!-- action -->
                                <td class="text-center">
                                    <button 
                                        *ngIf="groupedTechPointWorkingHours?.length && perms.hasAccess(perms.CAN_ADD_EDIT_TECHNICAL_INSPECTION)" 
                                        class="btn btn-sm btn-primary mt-2" 
                                        (click)="onSubmitAvailableSlotForm()" 
                                        translate>button.save
                                    </button>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    </form>
</div>
&nbsp;

<!-- ****************************************************** 
************** Core data for creation *************
****************************************************** -->
<div class="container border mainbox">
    <div>
        &nbsp;
        <h1 class="title-position" translate>technicalInspection.technicalInspection</h1>
        &nbsp;
        
        <!-- Core data about reservation  -->
        <div *ngIf="showAllFields" class="card-body">
            <!-- line number -->
            <div class="row form-group input-custom-size">
                <h4 class="person-label-position" translate>technicalInspection.lineNumber</h4>
                <label class="ml-2 label-size">{{mainForm.lineNumber.value}}</label>
            </div> 

            <!-- slot -->
            <div *ngIf="currentTechnicalInspectionTimes != null" class="row form-group input-custom-size">
                <h4 class="person-label-position" translate>technicalInspection.slotLabel</h4>
                <div class="person-label-position" *ngFor="let ti of currentTechnicalInspectionTimes">
                    <label class="ml-2 label-size">{{ti?.technicalPointWorkingHours?.timeConstraint?.validFrom}} - {{ti?.technicalPointWorkingHours?.timeConstraint?.validTo}}</label>
                </div>
            </div>
            
            <!-- promocode -->
            <div *ngIf="hasPromocode" class="row form-group input-custom-size">
                <h4 class="person-label-position" translate>technicalInspection.promocode</h4>
                <label class="ml-2 label-size">{{technicalInspection.techInspectionOrders![0].order?.promotion?.name}}</label>
            </div> 
            
            <!-- status -->
            <div *ngIf="techInspStatusName !== null" class="row form-group input-custom-size">
                <h4 class="person-label-position" translate>technicalInspection.status</h4>
                <label class="ml-2 label-size">{{techInspStatusName}}</label>
            </div>
            
            <!-- order status -->
            <div *ngIf="technicalInspection?.techInspectionOrders != null" class="row form-group input-custom-size">
                <h4 class="person-label-position"  *ngIf="technicalInspection?.techInspectionOrders![0]?.order != null" translate>technicalInspection.orderStatus</h4>
                <label class="ml-2 label-size">{{technicalInspection.techInspectionOrders![0]?.order?.status?.name}}</label>
            </div>
            
            <!-- payment type -->
            <div *ngIf="technicalInspection?.techInspectionOrders != null" class="row form-group input-custom-size">
                <h4 class="person-label-position"  *ngIf="technicalInspection?.techInspectionOrders![0]?.order != null" translate>technicalInspection.paymentType</h4>
                <label class="ml-2 label-size">{{technicalInspection.techInspectionOrders![0]?.order?.paymentType?.description}}</label>
            </div>
            
            <!-- mobile number -->
            <!-- <div *ngIf="techInspMobileId !== null" class="row form-group input-custom-size">
                <h4 class="person-label-position" translate>technicalInspection.mobileNumber</h4>
                <label class="ml-2 label-size">{{techInspMobileId}}</label>
            </div> -->
            
            <!-- subscription -->
            <div *ngIf="customerSubscriptionName !== null" class="row form-group input-custom-size">
                <h4 class="person-label-position" translate>technicalInspection.subscription</h4>
                <label class="ml-2 label-size">{{customerSubscriptionName}}</label>
            </div>
        </div>

        <!-- Creation data -->
        <div id="accordion" *ngIf="loadTechnicalInspection$ | async as data; else loading">
            <!-- reg number, category and basic service-->
            <form [formGroup]="mainTechInspForm" (ngSubmit)="onSubmitMainForm()">
                <div class="card">
                    <div class="card-header" >
                        <h3 class="mb-0 title-position" translate>technicalInspection.stepOne</h3>
                    </div>
                    <div class="card-body">
                        <!-- reg number -->
                        <div class="form-group input-custom-size">
                            <h4 for="regNumber" translate>technicalInspection.registrationNumber</h4>
                            <input class="form-control d-inline input-custom-size" type="text" id="regNumberInput" placeholder="{{'placeholder.enter' | translate}}"
                                formControlName="regNumber" 
                                (focusout)="normalizeRegNumber('mainForm', mainForm.regNumber.value)"
                                (input)="validateMainFormRegNumber(mainForm.regNumber.value)"
                                [ngClass]="{ 'is-invalid': submittedMainForm && mainForm.regNumber.errors || isValidMainFormRegNum}">
                            <div class="text-danger" *ngIf="isValidMainFormRegNum"><small translate>errors.onlyLatinSymbolsAndDigits</small></div>
                            <ng-container *ngIf="mainFormValidation">
                                <div class="text-danger" *ngIf="isInvalidRegNumberForForeigner"><small translate>errors.regNumberForForeigner</small></div>
                                <div class="text-danger" *ngIf="isInvalidRegNumberForDiplomatic"><small translate>errors.regNumberForDiplomatic</small></div>
                                <div class="text-danger" *ngIf="isInvalidRegNumberForElectric"><small translate>errors.regNumberForElectric</small></div>
                            </ng-container>
                            <div class="invalid-feedback" *ngIf="submittedMainForm && mainForm.regNumber.errors?.required" translate>errors.required</div>
                            <div class="invalid-feedback" *ngIf="submittedMainForm && mainForm.regNumber.errors?.maxlength" translate>errors.tooLong</div>
                        </div>
                       
                        <!-- certification number -->
                        <!--<div class="form-group input-custom-size">
                            <h4 for="registrationCertNumber" translate>technicalInspection.certificationNumber</h4>
                            <input type="text" formControlName="registrationCertNumber" class="form-control input-custom-size" placeholder="{{'placeholder.enter' | translate}}"
                            (input)="validateMainFormRegCertNumber(mainForm.registrationCertNumber.value)"
                            [ngClass]="{ 'is-invalid': submittedMainForm && mainForm.registrationCertNumber.errors || isValidMainFormRegCertNum }">
                            <div class="text-danger" *ngIf="isValidMainFormRegCertNum"><small translate>errors.onlyCapitalLatinLettersAndNumbers</small></div>
                            <div class="invalid-feedback" *ngIf="mainForm.registrationCertNumber.errors?.required" translate>errors.required</div>
                            <div class="invalid-feedback" *ngIf="mainForm.registrationCertNumber.errors?.maxlength" translate>errors.tooLong</div>
                        </div> -->
                    
                        <!-- category -->
                        <div class="form-group input-custom-size">
                            <h4 for="rvsCategory" translate>technicalInspection.category</h4>
                            <select class="custom-select input-custom-size" formControlName="rvsCategory" [compareWith]="compareById" (change)='onRvsCategorySelect(mainForm.rvsCategory.value)'
                                    [ngClass]="{ 'is-invalid': submittedMainForm && mainForm.rvsCategory.errors }">
                                <option *ngFor="let category of rvsCategories" [ngValue]="category">
                                    {{category.description}}
                                </option>
                            </select>
                            <div class="invalid-feedback" *ngIf="mainForm.rvsCategory.errors?.required" translate>errors.required</div>
                        </div>
                        
                        <!-- basic service -->
                        <div class="form-group input-custom-size">
                            <h4 for="basicTechPointService" translate>technicalInspection.basicServices</h4>
                            <div class="form-check" *ngFor="let item of basicServices">
                                <input class="form-check-input radio-input-size" formControlName="basicTechPointService" type="radio" 
                                    [value]="item" (change)='onBasicTechPointServiceSelect()' 
                                    [ngClass]="{ 'is-invalid': submittedMainForm && mainForm.basicTechPointService.errors }" />
                                <label class="form-check-label radio-label-size ml-2" translate> {{item.serviceName}}</label>
                            </div>
                        </div>
                        <button *ngIf="!showAllFields && perms.hasAccess(perms.CAN_ADD_EDIT_TECHNICAL_INSPECTION)" class="btn btn-outline-success" type="button" (click)="onSubmitMainForm()" translate>button.startReview</button> 
                    </div>
                </div>
            </form>

            <!-- core -->
            <form [formGroup]="additionalTechInspForm" *ngIf="showAllFields" (ngSubmit)="onSubmitAdditionalForm()">
                <!-- additional and annual services -->
                <div class="card">
                    <div class="card-header">
                        <h3 class="mb-0 title-position" translate> technicalInspection.additionalAndAnnualServices</h3>
                    </div>
                    <div class="card-body">
                        <div class="form-group form-row">
                            <!-- additional services -->
                            <div class="col form-group">
                                <div class="card">
                                    <div class="card-header">
                                        <h5 class="mb-0 card-title" translate>technicalInspection.additionalServices</h5>
                                    </div>
                                    <div class="card-body">
                                        <div class="form-group">
                                            <div class="form-check" *ngFor="let item of additionalServices">
                                                <input type="checkbox" [value]="item" 
                                                class="form-check-input mt-2"  
                                                [checked]="checkId(item.id)"
                                                (change)="onChangeAdditionalService($event, item)">
                                                <label class="form-check-label checkboxtext"> {{item.serviceName}} </label>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
        
                            <!-- annual subscriptions -->
                            <div class="col form-group">
                                <div class="card">
                                    <div class="card-header">
                                        <h5 class="mb-0 card-title" translate>technicalInspection.annualSubscriptions</h5>
                                    </div>
                                    <div class="card-body"> 
                                        <div class="form-group">
                                            <div class="form-check" *ngFor="let item of annualServices">
                                                <input type="checkbox" class="form-check-input mt-2" [value]="item" [attr.disabled]="disableAnnualServiceAndDiscount"
                                                [checked]="annualService != null && annualService.id === item.id" (change)="onChangeAnnualService($event, item)">
                                                <label class="form-check-label checkboxtext"> {{item.serviceName}} </label>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="card">
                    <div class="card-header">
                        <h3 class="mb-0 title-position" translate>technicalInspection.stepTwo</h3>
                    </div>
                    <div class="card-body">
                        <!-- phone number -->
                        <div class="form-group input-custom-size">
                            <h4 for="phoneNumber" translate>technicalInspection.phoneNumber</h4>
                            <input type="text" formControlName="phoneNumber" placeholder="Пример:+359888112233" class="form-control input-custom-size" 
                            [ngClass]="{ 'is-invalid': submittedAdditionalForm && additionalForm.phoneNumber.errors }">
                            <div class="invalid-feedback" *ngIf="additionalForm.phoneNumber.errors?.maxlength" translate>errors.tooLong</div>
                            <div class="invalid-feedback" *ngIf="additionalForm.phoneNumber?.errors?.required" translate>errors.required</div>
                            <div class="invalid-feedback" *ngIf="additionalForm.phoneNumber?.errors?.invalidPhoneNumber" translate>errors.phoneInvalid</div>
                        </div>

                        <!-- email -->
                        <div class="form-group input-custom-size">
                            <h4 for="emailInput" translate>techPoint.email</h4>
                            <input class="form-control input-custom-size"  type="text" id="emailInput" placeholder="{{'placeholder.enter' | translate}}"
                            formControlName="email" [ngClass]="{ 'is-invalid': submittedAdditionalForm && additionalForm.email.errors}">
                            <div class="invalid-feedback" *ngIf="additionalForm.email?.errors?.email" translate>errors.mailInvalid</div>
                            <div class="invalid-feedback" *ngIf="additionalForm.email?.errors?.required" translate>errors.required</div>
                            <div class="invalid-feedback" *ngIf="additionalForm.email?.errors?.maxlength" translate>{{"errors.inputTooLong" | translate: {max: 60} }}</div>
                        </div>

                        <!-- subject name -->
                        <div class="form-group">
                            <h4 for="nameInput" translate>technicalInspection.subjectName</h4>
                            <input class="form-control input-custom-size" type="text" id="nameInput" placeholder="{{'placeholder.enter' | translate}}"
                                formControlName="name" [ngClass]="{ 'is-invalid': submittedAdditionalForm && additionalForm.name.errors}">
                            <div class="invalid-feedback" *ngIf="additionalForm.name?.errors?.required" translate>errors.required</div>
                            <div class="invalid-feedback" *ngIf="additionalForm.name?.errors?.maxlength" translate>{{"errors.inputTooLong" | translate: {max: 60} }}</div>
                        </div>

                        <!-- Customer discount autocomplete input (if needed) -->
                        <!-- <div class="form-group">
                            <h4 translate>technicalInspection.discount</h4>
                            <input [attr.disabled]="annualService || disableAnnualServiceAndDiscount"
                                type="text" class="form-control input-custom-size" formControlName="customerDiscount" 
                                [ngbTypeahead]="customerDiscountSearch" [inputFormatter]="customerDiscountFormatter"
                                [resultTemplate]="rtsv" (change)='onCustomerDiscountSelect()'/>
                            <ng-template #rtsv let-r="result" let-t="term">
                                <ngb-highlight [result]="r.discountName" [term]="t"></ngb-highlight>
                            </ng-template>
                        </div> -->

                        <!-- notification -->
                        <div class="form-group input-custom-size">
                            <h4 translate>technicalInspection.notification</h4>
                            <div class="form-check">
                                <input class="form-check-input radio-input-size" formControlName="isAgreeWithNotification" type="radio" [value]="true" />
                                <label class="form-check-label radio-label-size ml-2" translate>yes</label>
                            </div>
                            <div class="form-check">
                                <input class="form-check-input radio-input-size" formControlName="isAgreeWithNotification" type="radio" [value]="false" />
                                <label class="form-check-label radio-label-size ml-2" translate>no</label>
                            </div>
                        </div>

                        <!-- is employee -->
                        <div class="form-group input-custom-size">
                            <h4 translate>technicalInspection.isEmployee</h4>
                            <div class="form-check">
                                <input class="form-check-input radio-input-size" formControlName="isEmployee" type="radio" [value]="true" />
                                <label class="form-check-label radio-label-size ml-2" translate>yes</label>
                            </div>
                            <div class="form-check">
                                <input class="form-check-input radio-input-size" formControlName="isEmployee" type="radio" [value]="false" />
                                <label class="form-check-label radio-label-size ml-2" translate>no</label>
                            </div>
                        </div>

                        <!-- choose subject -->
                        <h5 *ngIf="isSubjectFromPromocode" style="margin-bottom: 1rem; text-decoration: underline;" translate>technicalInspection.subjectFromPromocode</h5>
                        <div class="form-group">
                            <button class="btn btn-primary" type="button" (click)="openModal()" translate>technicalInspection.chooseSubject</button>
                            <button *ngIf="additionalForm.subjectIdentNum.value != null" class="btn btn-danger ml-2" (click)="clearSubject()" type="button" translate>button.clearSubject</button>
                        </div>

                        <div *ngIf="additionalForm.subjectIdentNum.value != null">
                            <!-- bulstat -->
                            <div class="row form-group input-custom-size">
                                <h4 class="person-label-position ml-3" translate>technicalInspection.bulstat</h4>
                                <label class="ml-2 label-size">{{additionalForm.subjectIdentNum.value}}</label>
                            </div>
                            <!-- accountable person -->
                            <div class="row form-group input-custom-size">
                                <h4 class="person-label-position ml-3" translate>technicalInspection.accountablePerson</h4>
                                <label class="ml-2 label-size">{{additionalForm.subjectManager.value}}</label>
                            </div>
                            <!-- vatNumber -->
                            <div class="row form-group input-custom-size">
                                <h4 class="person-label-position ml-3" translate>technicalInspection.vatNumber</h4>
                                <label class="ml-2 label-size">{{additionalForm.subjectInvoiceDdsReg.value}}</label>
                            </div>
                            <!-- name -->
                            <div class="row form-group input-custom-size">
                                <h4 class="person-label-position ml-3" translate>technicalInspection.name</h4>
                                <label class="ml-2 label-size">{{additionalForm.subjectName.value}}</label>
                            </div>
                            <!-- place -->
                            <div class="row form-group input-custom-size">
                                <h4 class="person-label-position ml-3" translate>technicalInspection.place</h4>
                                <label class="ml-2 label-size">{{additionalForm.subjectCity?.value?.name}}</label>
                            </div>
                            <!-- address -->
                            <div class="row form-group input-custom-size">
                                <h4 class="person-label-position ml-3" translate>technicalInspection.address</h4>
                                <label class="ml-3 label-size">{{additionalForm.subjectAddress.value}}</label>
                            </div>
                        </div>

                        <!-- loyal card -->
                        <div class="form-group">
                            <h4 for="nameInput" translate>technicalInspection.loyaltyProgramCard</h4>
                            <input class="form-control input-custom-size" type="text" id="customerCard" placeholder="{{'placeholder.enter' | translate}}"
                                formControlName="customerCard" (change)="onChangeLoyaltyCard($event)" [readonly]="hasPromocode" />
                            <span style="color: rgb(206 16 16); font-size: 18px; margin-left: 3px;" *ngIf="displayExceedCardMessage" translate>technicalInspection.displayExceedCardMessage</span>
                            <span style="color: rgb(206 16 16); font-size: 18px; margin-left: 3px;"*ngIf="displayCardDoesNotExistMessage" translate>technicalInspection.displayCardDoesNotExistMessage</span>
                            <!-- <div class="invalid-feedback" *ngIf="additionalTechInspForm.loyaltyProgramCardNumber?.errors?.maxlength" translate>{{"errors.inputTooLong" | translate: {max: 7} }}</div> -->
                        </div>
                        
                        <!-- discounts -->
                        <div class="form-group input-custom-size mt-4">
                            <h4 for="rvsCategory" translate>technicalInspection.discount</h4>
                            <select class="custom-select input-custom-size" formControlName="customerDiscount" [compareWith]="compareById" [attr.disabled]="hasPromocode || annualService || disableAnnualServiceAndDiscount" (change)='onCustomerDiscountSelect()'>
                                <option *ngIf="technicalInspection?.techInspectionOrders == null || technicalInspection?.techInspectionOrders![0]?.order?.promotion?.id == null" value="" [attr.disabled]="disableTechPointServices" translate>placeholder.select</option>
                                <option [ngValue]="null" selected translate>placeholder.select</option>
                                <option *ngFor="let discount of customerDiscounts" [ngValue]="discount" [attr.disabled]="disableTechPointServices">
                                    {{ discount.discountName }}
                                </option>
                            </select>
                        </div>

                        <!-- with/without assistant -->
                        <div class="form-group input-custom-size" style="display: none;">
                            <h4 translate>technicalInspection.mannerOfPerformingTheService</h4>
                            <div class="form-check">
                                <label class="radio-inline radio-label-size" translate>
                                    <input class="radio-input-size" type="radio" [value]="1" checked="true"/>  Без асистент
                                </label>
                            </div>

                            <div class="form-check">
                                <label class="radio-inline radio-label-size" translate>
                                    <input class="radio-input-size" type="radio"  [value]="2"/>  С асистент
                                </label>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="card">
                    <div class="card-header">
                        <h3 class="mb-0 title-position" translate> technicalInspection.stepThree</h3>
                    </div>
                    <div id="collapseThree" class="collapse show" aria-labelledby="headingThree" data-parent="#accordion">
                        <!-- reject reasons -->
                        <div class="card-body">
                            <div class="form-group input-custom-size">
                                <select class="custom-select input-custom-size" formControlName="rejectReason" [compareWith]="compareById"
                                        (change)='onRejectReasonSelect(additionalForm.rejectReason.value)'>
                                    <option value='' translate>placeholder.select</option>
                                    <option *ngFor="let reason of rejectReasons" [ngValue]="reason">
                                        {{reason.description}}
                                    </option>
                                </select>
                            </div>
                        </div>
                    </div>

                    <!-- terminate && show payment table -->
                    <div class="text-right">
                        <button *ngIf="additionalForm.rejectReason.value != null && canEdit" class="btn btn-outline-success ml-2 mr-2 mb-1" type="button" 
                                (click)="onSubmitAdditionalFormTerminatedModal()" [disabled]="disableSaveButton" translate>
                            button.terminate
                        </button>
                        <button *ngIf="(isVisiblePaymentServicesTable == false && additionalForm.rejectReason.value == null) || isChangedRvsCategory==true" class="btn btn-outline-success mr-2 mb-1" type="button" 
                                (click)="showPaymentServicesTable()" translate>
                            button.showPaymentServiceData
                        </button>
                    </div>
                </div>
            </form>   
            
            <!-- Payment service table & action buttons -->
            <div class="card" *ngIf="isVisiblePaymentServicesTable && isChangedRvsCategory==false">
                <div class="card-header">
                    <h3 class="mb-0 title-position" translate> technicalInspection.stepFour</h3>
                </div>
                
                <!-- Payment service table -->
                <div class="card-body">
                    <div class="form-group form-row">
                        <table class="table table-bordered table-striped w-100" style=" font-size: 19px">
                            <thead>
                                <tr>
                                    <th translate>technicalInspection.service</th>
                                    <th translate>technicalInspection.type</th>
                                    <th translate>technicalInspection.price</th>
                                    <th translate>technicalInspection.discount</th>
                                </tr>
                            </thead>
                        
                            <tbody>
                                <tr *ngFor="let s of paymentServiceConvertedData">
                                    <td>{{s.serviceName}}</td>
                                    <td>{{s.serviceType}}</td>
                                    <td>{{s.amountDds | number:'1.0-2'}}</td>
                                    <td>{{s.calculatedDiscount | number:'1.0-2'}}</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>

                <ng-container *ngIf="isReservationPaid && orderPriceMismatch">
                    <div class="card">
                        <div class="card-header">
                            <h3 class="mb-0 title-position" translate>Корекция предплатени</h3>
                        </div>
                        <div class="card-body">
                            <div class="form-group">
                                <h5 style="display: flex; align-items: center; justify-content: center; height: 30px; margin-top: 1rem;">
                                    <span style="font-size: 50px; height: 74px;">&#8680;</span>
                                    <span>
                                        Платените услуги са на стойност {{orderAmountPaid | number:'1.2-2'}} лева, след корекцията има за 
                                        <span *ngIf="orderAmountPaid > currentAmountServices">връщане {{(orderAmountPaid - currentAmountServices) | number:'1.2-2'}}</span>
                                        <span *ngIf="currentAmountServices > orderAmountPaid">доплащане {{(currentAmountServices - orderAmountPaid) | number:'1.2-2'}}</span>
                                        лева.
                                    </span>
                                </h5>
                            </div>
                        </div>
                    </div>
                    <br>
                </ng-container>

                <!-- action buttons -->
                <div class="text-right">
                    <button class="btn btn-outline-success mr-1 mb-1" 
                            *ngIf="!hasTechInspectionOrder()"
                            type="button" 
                            (click)="onSubmitAdditionalFormAndIssueInvoice()" 
                            [disabled]="disableSaveButton" 
                            translate>button.createAccDoc
                    </button>
                    <button class="btn btn-outline-success mr-1 mb-1" 
                            *ngIf="hasTechInspectionOrder()"
                            type="button" (click)="onSubmitViewInvoice()" 
                            [disabled]="disableSaveButton" 
                            translate>button.viewAccDoc
                    </button>
                    <button class="btn btn-outline-success mr-1 mb-1" 
                            *ngIf="canEdit && showCreditDebitButton"
                            type="button" 
                            (click)="onSubmitViewCreditOrDebit()" 
                            [disabled]="disableSaveButton" 
                            translate>button.viewCreditDebit
                    </button>

                    <button class="btn btn-outline-success mb-1" 
                            *ngIf="perms.hasAccess(perms.CAN_ADD_EDIT_TECHNICAL_INSPECTION) 
                                    && canEdit && 
                                        ((isCredit == false && isDebit == false
                                            && technicalInspection?.techInspectionOrders != null
                                            && technicalInspection?.techInspectionOrders![0]?.order?.paymentType?.description != CARD_PAYMENT_TYPE
                                        ) || technicalInspection?.techInspectionOrders == null)"  
                            type="button" 
                            (click)="onSubmitAdditionalFormDeferPayment()" 
                            [disabled]="disableSaveButton" 
                            translate>
                            button.deferPayment
                    </button>

                    <!-- Bank Payment -->
                    <ng-container *ngIf="!reservationPaidAlready">
                        <button class="btn btn-outline-success mb-1 ml-1" 
                                *ngIf="perms.hasAccess(perms.CAN_ADD_EDIT_TECHNICAL_INSPECTION) && canEdit && (isCredit == false && isDebit == false)" 
                                type="button" 
                                (click)="onSubmitAdditionalFormBankPayment()" 
                                [disabled]="disableSaveButton" 
                                translate>
                                button.bankPayment
                        </button>
                    </ng-container>

                    <!-- Pay In Tech Point -->
                    <button class="btn btn-outline-success w-20 ml-1 mb-1 mr-2" 
                        *ngIf="(technicalInspection?.status?.code !== CODE_FINISHED 
                                && isCredit == false && isDebit == false 
                                && technicalInspection?.techInspectionOrders != null
                                    && technicalInspection?.techInspectionOrders![0]?.order?.paymentType?.description != CARD_PAYMENT_TYPE)
                                || technicalInspection?.techInspectionOrders == null"  
                        type="button" 
                        (click)="onSubmitAdditionalForm()" 
                        [disabled]="disableSaveButton" 
                        translate>
                        button.payInTechPoint
                    </button>

                    <button class="btn btn-outline-success w-20 ml-1 mb-1 mr-2" 
                            [disabled]="disableSaveButton" 
                            *ngIf="technicalInspection?.status?.code == CODE_FINISHED 
                                    && (customerSubscriptionName == null || customerSubscriptionName == undefined) 
                                    && isCredit == false && isDebit == false
                                    || (technicalInspection?.techInspectionOrders != null
                                        && technicalInspection?.techInspectionOrders![0]?.order?.paymentType?.description == CARD_PAYMENT_TYPE
                                        && isCredit == false && isDebit == false)"   
                            type="button" 
                            (click)="onSubmitAdditionalForm()" 
                            translate>button.save
                    </button>
                    <button class="btn btn-outline-success mr-1 mb-1" 
                            *ngIf="isCredit" 
                            (click)="generateCreditDocument()"
                            type="button" 
                            [disabled]="disableSaveButton" 
                            translate>technicalInspection.createCredit
                    </button>
                    <button class="btn btn-outline-success mr-1 mb-1" 
                            *ngIf="isDebit" 
                            (click)="generateDebitDocument()"
                            type="button" 
                            [disabled]="disableSaveButton" 
                            translate>technicalInspection.createDebit
                    </button>
                </div>
            </div>
        </div>
    </div>
</div>
&nbsp;

<ng-template #loading>
    <div *ngIf="errorMessage$ | async as errorMessage; else spinner">
        <div class="alert alert-danger">
            {{errorMessage}}
        </div>
        <div class="d-flex justify-content-center">
            <i class="fa fa-refresh" (click)="reload()"></i>
        </div>
    </div>
    <ng-template #spinner>
        <div class="d-flex justify-content-center">
            <div class="spinner-border" role="status">
                <span class="sr-only">Loading...</span>
            </div>
        </div>
    </ng-template>
</ng-template>

<ng-template #confirmTermination>
    <h2 mat-dialog-title translate style="font-size: 1.6rem;">button.confirm</h2>
    <mat-dialog-content>
        <p translate style="font-size: 1.1rem;"><p>
    </mat-dialog-content>
    <mat-dialog-actions align="end">
        <div class="text-right">
            <button mat-button [mat-dialog-close]="false" class="btn btn-outline-secondary ml-2 mr-2 mb-1" type="button" 
                (click)="onSubmitAdditionalFormTerminated()" [disabled]="disableSaveButton" translate>
                no
            </button>
            <button mat-button [mat-dialog-close]="true" class="ml-2 mr-2 mb-1 btn btn btn-danger" type="button" 
                (click)="onSubmitAdditionalFormTerminated()" [disabled]="disableSaveButton" translate>
                button.terminate
            </button>
        </div>
    </mat-dialog-actions>
</ng-template>

<ng-template #confirmAnnul>
    <h2 mat-dialog-title translate style="font-size: 1.6rem;">button.confirm</h2>
    <mat-dialog-content>
        <p translate style="font-size: 1.1rem;"><p>
    </mat-dialog-content>
    <mat-dialog-actions align="end">
        <div class="text-right">
            <button mat-button [mat-dialog-close]="false" class="btn btn-outline-secondary ml-2 mr-2 mb-1" type="button" 
                (click)="onSubmitAdditionalFormTerminated()" [disabled]="disableSaveButton" translate>
                no
            </button>
            <button mat-button [mat-dialog-close]="true" class="ml-2 mr-2 mb-1 btn btn btn-danger" type="button" 
                (click)="onSubmitAdditionalFormTerminated()" [disabled]="disableSaveButton" translate>
                button.annul
            </button>
        </div>
    </mat-dialog-actions>
</ng-template>

<ng-template #dataChange>
    <h2 mat-dialog-title translate style="font-size: 1.6rem;">button.change</h2>
    <mat-dialog-content>
        <p translate style="font-size: 1.1rem;">technicalInspection.dataChange<p>
    </mat-dialog-content>
    <mat-dialog-actions align="end">
        <div class="text-right">
            <button mat-button [mat-dialog-close]="true" class="btn btn-outline-secondary ml-2 mr-2 mb-1" type="button" 
                (click)="onDataChangeConfirmationModal()" [disabled]="disableSaveButton" translate>
                messages.ok
            </button>
        </div>
    </mat-dialog-actions>
</ng-template>