import { Component, OnDestroy, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { EMPTY, forkJoin, Observable, of, OperatorFunction, Subject, Subscription } from 'rxjs';
import { catchError, concatMap, debounceTime, filter, repeatWhen, shareReplay, switchMap, tap } from 'rxjs/operators';
import { TranslateService } from '@ngx-translate/core';

import { RegisterOfLegalPersonsModalComponent } from '@components/register-of-legal-persons-modal/register-of-legal-persons-modal.component';
import { WarningMessageboxComponent } from '@components/_base/_messageboxes/warning-messagebox/warning-messagebox.component';
import { YesNoMessageboxComponent } from '@components/_base/_messageboxes/yes-no-messagebox/yes-no-messagebox.component';
import { BaseParentComponent } from '@components/_base/base-parent/base-parent.component';

import { TechnicalInspectionService } from '@app/technical-inspections/_services/technical-inspection.service';
import { TechnicalPointService } from '@app/tech-points/_services/technical-point.service';
import { CustomerCardService } from '@app/card-config/_services/customer-card.service';
import { AuthenticationService } from '@app/login/_services/authentication.service';
import { PermissionsService } from '@app/login/_services/permissions.service';
import { ContractService } from '@app/contracts/_services/contract.service';
import { NomenclatureService } from '@services/nomenclature.service';

import { UtilValidators } from '@app/_validators/util.validators';

import { config } from '@env/config';
import { modalMinWidth } from '@env/config';

import { PaymentServiceData, createPaymentServiceData } from '@app/technical-inspections/_models/payment-service-data.model';
import { GroupedTechnicalPointWorkingHour } from '@app/tech-points/_models/grouped-technical-point-working-hours.model';
import { CustomerSubscriptionService } from '@app/customers/_models/customer-subscription-service.model';
import { TechnicalInspection } from '@app/technical-inspections/_models/technical-inspection.model';
import { CustomerSubscription } from '@app/customers/_models/customer-subscription.model';
import { TechPointService } from '@app/tech-points/_models/tech-point-service.model';
import { ConsiceSubjectVersion } from '@models/consice-subject-version.model';
import { CustomerCards } from '@app/card-config/_models/customer-cards.model';
import { TechInspectionTime } from '@models/tech-inspection-time.model';
import { CustomerDiscount } from '@models/customer-discount.model';
import { SubjectVersion } from '@models/subject-version.model';
import { PriceAttribute } from '@models/price-attribute.model';
import { Line } from '@app/tech-points/_models/line.model';
import { RejectReason } from '@models/reject-reason.model';
import { RvsCategory } from '@models/rvs-category.model';
import { LoyalCombo } from '@models/loyal-combo.model';
import { StatusCode } from '@app/_enums/status-code';
import { Status } from '@models/status.model';

import { prepareDateForDb, prepareDateForForm } from '@app/_utils/date-util';
import { UIEventCustom } from '@app/_utils/ui-event-util';
import { displayError } from '@app/_utils/error-util';
import { PromotionService } from '@app/promotions/_services/promotion.service';

@Component({
  selector: 'app-add-edit-technical-inspection',
  templateUrl: './add-edit-technical-inspection.component.html',
  styleUrls: ['./add-edit-technical-inspection.component.css']
})
export class AddEditTechnicalInspectionComponent extends BaseParentComponent implements OnInit, OnDestroy {
  // Units
  technicalInspection:                TechnicalInspection;
  annualService:                      TechPointService | null;
  defRvsCategory:                     RvsCategory | null;
  customerSubscription:               CustomerSubscription | null;
  groupedTechPointWorkingHours:       GroupedTechnicalPointWorkingHour[];
  paymentServiceConvertedData:        Array<PaymentServiceData> = [];
  basicServicesForFirstAvailableSlot: TechPointService[];
  paymentServiceTableData:            Array<TechPointService> = [];
  technicalInspectionTimes:           TechInspectionTime[] | null;
  futureTechInspections:              TechnicalInspection[];
  additionalServices:                 TechPointService[];
  customerDiscounts:                  CustomerDiscount[];
  initialCustomerDiscounts:           CustomerDiscount[];
  annualServices:                     TechPointService[];
  basicServices:                      TechPointService[];
  basicServicesCopy:                  TechPointService[];
  rejectReasons:                      RejectReason[];
  rvsCategories:                      RvsCategory[];
  technicalPointLines:                Line[];
  techInspStatuses:                   Status[];
  customerCard:                       CustomerCards | null;
  priceAttribute:                     PriceAttribute;
  currentTechnicalInspectionTimes:    TechInspectionTime[];
  
  // Constants
  readonly RESP_OK                          = 'OK';
  readonly RESP_CURRENT_SLOT_NOT_AVAILABLE  = 'CURRENT_SLOT_NOT_AVAILABLE';
  readonly RESP_HAS_NOT_AVAILABLE_SLOT      = 'HAS_NOT_AVAILABLE_SLOT';
  readonly BASIC_SERVICE_NAME               = 'ГОДИШЕН ТЕХНИЧЕСКИ ПРЕГЛЕД';
  readonly BASIC_SERVICE_TYPE               = 'Основна услуга';
  readonly CARD_PAYMENT_TYPE                = 'С карта';
  readonly DISCOUNT_PERCENTAGE              = 'P';
  readonly RVS_CATEGORY_CODE                = 'M1';
  readonly CODE_RESERVED                    = 'RESERVED';
  readonly CODE_FINISHED                    = 'FINISHED';
  readonly CODE_TERMINATED                  = 'TERMINATED';
  readonly CODE_ANNULLED                    = 'ANNULLED';
  readonly CODE_LATED                       = 'LATED';
  readonly CODE_STARTED                     = 'STARTED';
  readonly CODE_PAID                        = 'PAID';
  readonly CODE_ACTIVE                      = 'ACTIVE';
  readonly CODE_AWAITING_PAYMENT            = 'AWAITING_PAYMENT'
  readonly DISCOUNT_VALUE                   = 'V';
  readonly INCOMING_WAY_TP                  = 'erp_tp';
  readonly INVALID_LOYAL_CARD               = "INVALID_LOYAL_CARD";
  readonly DISCOUNT_TYPE_TECHNICAL_POINT    = "tp";
  readonly FINISHED_RES                     = "FINISHED";
  readonly SITE_INCOMING_WAY                = "autotest_site";

  // Booleans
  isNotFromWhilli                   = true;
  loading                           = false;
  showAllFields                     = false;
  showLateButton                    = false;
  showStartButton                   = false;
  submittedMainForm                 = false;
  isStartedTechInsp                 = false;
  hasValidSubscription              = false;
  isValidMainFormRegNum             = false;
  isValidSlotFormRegNum             = false;
  submittedAdditionalForm           = false;
  hideSaveAndSubjectButtons         = false;
  //isValidMainFormRegCertNum       = false;
  submittedAvailableSlotForm        = false;
  isVisiblePaymentServicesTable     = false;
  issueInvoice                      = false;
  disableTechPointServices:         boolean | null;
  disableMainTechPointServices:     boolean | null;
  disableAnnualServiceAndDiscount:  boolean | null;
  isDeferPayment                    = false;
  isBankPayment                     = false;
  hasPromocode                      = false;
  viewInvoice                       = false;
  disableSaveButton                 = false;
  isChangedRvsCategory              = false;
  canEdit                           = true;
  arePastLoaded                     = false;
  isPastClicked                     = false;
  isCredit                          = false;
  isDebit                           = false;
  viewCreditAndDebit                = false;
  showCreditDebitButton             = false;
  loadingQuickReservation           = false;
  isInvalidRegNumberForForeigner    = false;
  isInvalidRegNumberForDiplomatic   = false;
  isInvalidRegNumberForElectric     = false;
  mainFormValidation                = false;
  slotFormValidation                = false;
  isHaveSubject                     = false;
  agreeWithNotification!:     boolean | null;
  isReservationPaid                 = false;
  orderPriceMismatch                = false;
  orderPaid                         = false;
  isSubjectFromPromocode            = false;
  displayExceedCardMessage          = false;
  displayCardDoesNotExistMessage    = false;
  wantMarketingNotification         = true;
  reservationPaidAlready            = false;

  // Payloads
  userTechnicalPointMobileIds:        number[];
  userTechnicalPointId:               number;
  techPointWorkingHourIds:            number[];
  currentSelectedServiceMobileAppIds: Array<number> = [];
  servicesMobileAppIdsForCard:        number[];
  technicalInspectionId:              number;
  techInspMobileId:                   number | null;
  customerSubscriptionName:           String | null;
  techInspStatusName:                 string | null;
  refreshIntervalId:                  any;
  subjectMobileAppId:                 number;
  selectedAdditionalServices:         Array<any> = [];
  selectedTechPointServices:          Array<any> = [];
  minDate:                            { year: number, month: number, day: number };
  lastSelectedDiscountByCard          = '';
  orderElementsByPromotionCode:       any[] = [];
  allowedCustomerCardDiscountServices = ["ГОДИШЕН ТЕХНИЧЕСКИ ПРЕГЛЕД"];
  paidOrderCode                         = 'PAID';
  paymentTypeCardName                   = 'С карта';
  orderAmountPaid                       = 0;
  currentAmountServices                 = 0;
  futureTechInspId                      = 0;
  lastTechInspId                        = 0;
  techInspModalPopUpCounter             = 0;
  promocodeAvailableServices: string[]  = [];
  promocodeAvailableCategories: string[]= [];

  // Form
  availableSlotForm = this.formBuilder.group({
    forDate:                      [''],
    line:                         [''],
    regNumber:                    ['', [Validators.required, Validators.maxLength(16)]],
    phoneNumber:                  ['', [Validators.maxLength(16), UtilValidators.validatePhoneNumber()]],
    rvsCategory:                  ['', [Validators.required]],
    isEmployee:                   [false],
    basicTechPointService:        [''],
    groupedTechPointWorkingHours: [''],
  });

  mainTechInspForm = this.formBuilder.group({
    id:                     [''],
    regNumber:              ['', [Validators.required, Validators.maxLength(16)]],
    registrationCertNumber: ['', [Validators.maxLength(10)]],
    rvsCategory:            ['', [Validators.required]],
    basicTechPointService:  ['', [Validators.required]],
    inspectionDate:         [''],
    versionData:            [''],
    status:                 [''],
    lineNumber:             [''],
    customerRvsId:          [''],
    technicalPointId:       [''],
    incomingWay:            [''],
    fromErp:                [''],
  });

  additionalTechInspForm = this.formBuilder.group({
    phoneNumber:             ['',   [Validators.required, Validators.maxLength(16), UtilValidators.validatePhoneNumber()]],
    email:                   ['',   [Validators.required, Validators.email, Validators.maxLength(60)]],
    name:                    ['',   [Validators.required, Validators.maxLength(60)]],
    customerCard:            [null],     
    customerDiscount:        [''],
    promotionId:             [null],     
    isAgreeWithNotification: [true, [Validators.required]],
    isEmployee:              [false, [Validators.required]],
    rejectReason:            [null],
    createdOrder:            [''],
    issueInvoice:            [false],
    contractRvsId:           [null],
    subjectId:               [null],
    subjectIdentNum:         [null],
    subjectManager:          [null],
    subjectInvoiceDdsReg:    [null],         
    subjectName:             [null],
    subjectCity:             [null],
    subjectAddress:          [null],
    subjectVersionData:      [null]
  });
  
  // Observables
  private subscriptions = new Subscription();
  
  loadTechnicalInspection$ = this.route.queryParams.pipe(
    tap((params) => this.technicalInspectionId = params['id']),
    concatMap(() => {
      return this.techPointService.getTechPointIdByTechPointMobileAppId(this.userTechnicalPointMobileIds[0]);
    }),
    tap(techPointId => {
      this.userTechnicalPointId = techPointId;
    }),
    concatMap(() => {
      return forkJoin([
        this.techInspService.findTechnicalInspectionById(this.technicalInspectionId),
        this.techInspService.getTechInspectionNomenclatures(StatusCode.TechnicalInspection, this.userTechnicalPointId, this.userTechnicalPointMobileIds[0])
      ]).pipe(
        tap(([techInspection, [futureTechInspections, lines, rvsCategories, rejectReasons, customerDiscounts, techInspStatuses]]) => {
          this.mainTechInspForm.get('technicalPointId')?.patchValue(this.userTechnicalPointId);
          this.currentTechnicalInspectionTimes = techInspection?.techInspectionTimes!;
          
          //this check remove of falidation not work when button "button.fill" from fast rezervation list
          if (techInspection?.incomingWay?.includes('whilli')) {
            this.isNotFromWhilli = false;
            this.addRemoveValidatorsWhilli();
          } else {
            this.isNotFromWhilli = true;
            this.addRemoveValidatorsWhilli();
          }
          
          this.mainTechInspForm.patchValue(techInspection);
          this.additionalTechInspForm.patchValue(techInspection);
          this.technicalInspection = techInspection;
          if (this.technicalInspection && this.technicalInspection.techInspectionOrders) {
            this.technicalInspection.techInspectionOrders?.forEach(o => {
              this.reservationPaidAlready = o.order?.status.code === this.paidOrderCode ? true : false;

              if (o.order?.status.code === this.paidOrderCode && o.order.paymentType.description === this.paymentTypeCardName) {
                this.isReservationPaid = true;
                this.orderAmountPaid = 0;
                const firstVersionData = this.findLastFirstVersion(techInspection.versionData);
                this.techInspService.getPaidAmountFromFirstTechnicalInspection(firstVersionData.id).subscribe(res => {
                  this.orderAmountPaid = res;
                });
              } else {
                this.isReservationPaid = false;
              }
            });
          }
          
          this.futureTechInspections = futureTechInspections;
          this.futureTechInspections.forEach(techInsp => {
            techInsp.inspectionDate = this.prepareDateForUi(techInsp.inspectionDate);
          });

          this.showHideLateAndStartButton(this.futureTechInspections);
          this.technicalPointLines = lines;
          this.rvsCategories = rvsCategories;
          this.rejectReasons = rejectReasons;
          this.customerDiscounts = this.filterDiscountsByType(customerDiscounts);
          this.initialCustomerDiscounts = this.customerDiscounts;
          this.techInspStatuses = techInspStatuses;

          this.wantMarketingNotification = this.technicalInspection?.wantsMarketingMessages ?? true;

          if (this.technicalInspection?.techInspectionOrders![0]?.order) {
            if (this.technicalInspection?.techInspectionOrders![0]?.order?.status?.code == this.CODE_PAID) {
              this.orderPaid = true;
            }
          }

          if (techInspection && techInspection.customerCard) {
            this.customerCard = techInspection.customerCard;
            if (this.customerCard.programMobileId && this.customerCard.programMobileId.subjectVersion) {
              this.setSubjectDataWithoutContract(this.customerCard.programMobileId.subjectVersion, true);
            }
            if (this.customerCard.cardNumber) {
              let isHaveTechInspectionOrders = false;
              this.additionalTechInspForm.get('customerCard')?.setValue(this.customerCard.cardNumber);
              
              if (techInspection.techInspectionOrders && techInspection.techInspectionOrders.length !== 0) {
                if (techInspection.techInspectionOrders[0].order) {
                  isHaveTechInspectionOrders = true;
                  if (techInspection.techInspectionOrders[0].order.customerDiscount) {
                    this.additionalTechInspForm.get('customerDiscount')?.setValue(techInspection.techInspectionOrders[0].order.customerDiscount);
                    this.customerDiscounts.push(techInspection.techInspectionOrders[0].order.customerDiscount);
                    customerDiscounts = Array.from(new Set(customerDiscounts));
                  }
                }
              }

              if (!isHaveTechInspectionOrders) {
                this.cardService.getLoyalComboByCard(this.customerCard.cardNumber).subscribe(data => {
                  const loyalCombo = data as LoyalCombo;
                  this.customerCard = loyalCombo.customerCard;
                  if (loyalCombo.customerDiscount) {
                    this.lastSelectedDiscountByCard = loyalCombo.customerDiscount.discountName;
                    this.additionalTechInspForm.get('customerDiscount')?.setValue(loyalCombo.customerDiscount);
                    this.customerDiscounts.push(loyalCombo.customerDiscount);
                    customerDiscounts = Array.from(new Set(customerDiscounts));
                  }
                });
              }
            }
          }

          this.loadDefaultAvailableSlotFilters();

          if (techInspection == null) {
            this.showAllFields = false;
            this.mainTechInspForm.get('inspectionDate')?.patchValue(null);
            this.mainTechInspForm.get('rvsCategory')?.patchValue(this.defRvsCategory);
            this.rvsAdditionalSubject.next(this.defRvsCategory);
            this.additionalTechInspForm.get('isAgreeWithNotification')?.patchValue(true);
          } else {
            if (this.technicalInspection.status!.code !== this.CODE_RESERVED) {
              this.findAlreadyStarted();
              this.showAllFields = true;
            }
            if (this.technicalInspection.status!.code === this.CODE_PAID) {
              this.disableTechPointServices = true;
            }
            if (this.technicalInspection.status!.code === this.CODE_FINISHED || this.technicalInspection.status!.code === this.CODE_ANNULLED || this.technicalInspection.status!.code === this.CODE_LATED) {
              this.hideSaveAndSubjectButtons = true;
            }

            this.techPointWorkingHourIds = this.technicalInspection.techPointWorkingHourIds!;
            this.techInspStatusName = this.technicalInspection.status!.name;
            this.techInspMobileId = this.technicalInspection.mobileId!;
            this.rvsAdditionalSubject.next(techInspection.rvsCategory);

            const res = this.technicalInspection.techInspectionOrders?.[0]?.order?.invoiceSubjectVersion;
            if (this.technicalInspection.techInspectionOrders?.[0]?.order?.promotion?.id != null) {
                const promotionId = this.technicalInspection.techInspectionOrders[0]?.order?.promotion?.id;
                this.promotionService.findSubjectByPromotionId(promotionId).subscribe(sub => {
                    if (sub && this.technicalInspection.status?.code !== this.CODE_FINISHED && !res) {
                        this.setSubjectData(sub, false, false);
                        this.isSubjectFromPromocode = true;
                    }
                });
                this.fillPromoRvsAndServices(promotionId);
                this.hasPromocode = true;

                this.orderElementsByPromotionCode = this.technicalInspection.techInspectionOrders![0]?.order?.orderElements;
            }
          }
        }),
        catchError(err => {
          displayError(err);
          this.errorMessageSubject.next(this.translateService.instant('messages.errorLoadingData'));
          return EMPTY;
        }),
        repeatWhen(() => this.reload$)
      );
    })
  );

  private rvsAdditionalSubject = new Subject<RvsCategory | null>();
  rvsAdditional$ = this.rvsAdditionalSubject.asObservable().pipe(
    concatMap(rvsCategory => {
      return this.techInspService.getRvsCategoryServicesByType(rvsCategory, this.userTechnicalPointId).pipe(
        tap(([basicServices, additionalServices, annualServices]) => {
          this.basicServices = basicServices;
          this.basicServicesCopy = JSON.parse(JSON.stringify(basicServices));
          this.additionalServices = additionalServices;
          this.annualServices = annualServices;
          this.customerSubscriptionName = null;
          this.techInspStatusName = null;
          this.techInspMobileId = null;

          if (this.technicalInspectionId == null) {
            this.mainTechInspForm.get('basicTechPointService')?.patchValue(this.basicServices.find(service => service.serviceName === this.BASIC_SERVICE_NAME) || null);
            //TODO: Return when whilli is in use - automatically select the annual service when we start the technical inspection.
            // this.annualService = annualServices[0];
            // this.getServicesMobileAppIdsForCard(this.annualService!.serviceId!);
          } else {
            if (this.technicalInspection?.incomingWay === this.SITE_INCOMING_WAY) {
              if (this.technicalInspection.status?.code === this.FINISHED_RES) {
                this.agreeWithNotification = this.technicalInspection.isAgreeWithNotification || true;
              } else {
                this.agreeWithNotification = true;
              }
              this.additionalTechInspForm.get('isAgreeWithNotification')?.patchValue(this.agreeWithNotification);
            } else {
              if (this.technicalInspection.isAgreeWithNotification === true || this.technicalInspection.isAgreeWithNotification === false) {
                this.agreeWithNotification = this.technicalInspection.isAgreeWithNotification;
              } else {
                this.agreeWithNotification = true;
              }
              this.additionalTechInspForm.get('isAgreeWithNotification')?.patchValue(this.agreeWithNotification);
            }

            this.additionalTechInspForm.get('isEmployee')?.patchValue(this.technicalInspection.isEmployee == null ? false : this.technicalInspection.isEmployee);
            this.mainTechInspForm.get('lineNumber')?.patchValue(this.technicalInspection.techInspectionTimes![0].technicalPointWorkingHours!.line.number);
            this.mainTechInspForm.get('technicalPointId')?.patchValue(this.technicalInspection.technicalPoint?.id);
            this.additionalTechInspForm.get('issueInvoice')?.patchValue(this.technicalInspection.issueInvoice);
            this.additionalTechInspForm.get('isEmployee')?.patchValue(this.technicalInspection.isEmployee);
           
            if (this.technicalInspection.status!.code === this.CODE_RESERVED) {
              // this.additionalTechInspForm.get('isAgreeWithNotification')?.patchValue(true);
              this.additionalTechInspForm.get('isEmployee')?.patchValue(false);
              if (!this.technicalInspection?.incomingWay?.includes('whilli')) {
                if (this.annualServices.length > 0) {
                  this.annualServices.forEach(element => {
                    this.technicalInspection.techInspectionServices?.forEach(service => {
                      if (element.id === service.technicalPointService?.id) {
                        this.annualService = element;
                      }
                    });
                  });
                } else {
                  this.annualService = annualServices[0];
                }
                if (this.annualService) {
                  this.getServicesMobileAppIdsForCard(this.annualService!.serviceId!);
                }
                //for auto select cart check here if this.annualService is null and set  annualServices[0];
              }
            } 
            //TODO: Return when whilli is in use - automatically select the annual service when tech.insp. status is started

            // else if(this.technicalInspection.status!.code === this.CODE_STARTED) { // if technical point resume in status started to fix problem 
            //   if (!this.technicalInspection?.incomingWay?.includes('whilli')) {
            //     // this.annualService = annualServices[0];
            //     // this.getServicesMobileAppIdsForCard(this.annualService!.serviceId!);
            //   }
            // }

            // Basic 
            this.basicServices.forEach(basicTechPointService => {
              this.technicalInspection.techInspectionServices?.forEach(techInspService => {
                if (basicTechPointService.serviceMobileAppId === techInspService.technicalPointService!.serviceMobileAppId) {
                  this.mainTechInspForm.get('basicTechPointService')?.patchValue(basicTechPointService);
                }
              });
            });

            if (this.technicalInspection.status!.code !== this.CODE_RESERVED) {
                this.isVisiblePaymentServicesTable = true;

                let invoiceSubjVer: ConsiceSubjectVersion | undefined;
                if (this.technicalInspection.techInspectionOrders?.length != 0) {
                  if (this.technicalInspection.techInspectionOrders?.length != 1) {
                    invoiceSubjVer = this.technicalInspection.techInspectionOrders![this.technicalInspection.techInspectionOrders!.length - 1].order?.invoiceSubjectVersion;
                  } else {
                    invoiceSubjVer = this.technicalInspection.techInspectionOrders![0].order?.invoiceSubjectVersion;
                  }
                }
                this.setSubjectData(invoiceSubjVer, false, true);
                // Additional 
                this.additionalServices.forEach(additionalTechPointService => {
                  this.technicalInspection.techInspectionServices?.forEach(techInspService => {
                      if(additionalTechPointService.id === techInspService.technicalPointService!.id) {
                        this.selectedAdditionalServices.push(additionalTechPointService);
                      }
                  });
                });

                // Annual
                this.annualServices.forEach(annualTechPointService => {
                  this.technicalInspection.techInspectionServices?.forEach(techInspService => {
                      if (annualTechPointService.id === techInspService.technicalPointService!.id) {
                        this.annualService = annualTechPointService;
                        this.customerSubscriptionName = annualTechPointService.serviceName!;
                      }
                  });
                });

                if (this.techInspStatusName == null) {
                  if (this.technicalInspection.status?.name) {
                    this.techInspStatusName = this.technicalInspection.status?.name;
                  }
                }
                if (this.techInspMobileId == null) {
                  if (this.technicalInspection.mobileId) {
                    this.techInspMobileId = this.technicalInspection.mobileId;
                  }
                }
                if (this.customerSubscriptionName == null) {
                  this.getCustomerSubscriptionByRegNumber(this.mainTechInspForm.get('regNumber')?.value);
                }

                let custDisc: CustomerDiscount | undefined;
                if (this.technicalInspection.techInspectionOrders?.length != 0) {
                  if (this.technicalInspection.techInspectionOrders?.length != 1) {
                    custDisc = this.technicalInspection.techInspectionOrders![this.technicalInspection.techInspectionOrders!.length - 1].order?.customerDiscount;
                  } else {
                    custDisc = this.technicalInspection.techInspectionOrders![0].order?.customerDiscount;
                  }

                  if (this.technicalInspection.techInspectionOrders![0]?.order?.accountingDocument?.invoice != null) {
                    this.showCreditDebitButton = true;
                  }

                  if (this.technicalInspection) {
                    if (!this.technicalInspection.customerCard) {
                      this.additionalTechInspForm.get('customerDiscount')?.patchValue(custDisc);
                    }
                  }
                } 
            }

            if (this.technicalInspection.techInspectionOrders?.length == 0) {
              this.isVisiblePaymentServicesTable = false;
            } else {
              this.technicalInspection.techInspectionOrders?.forEach(techInspOrder => {
                techInspOrder.order!.orderElements.forEach(orderElement => {
                  this.paymentServiceConvertedData.push(createPaymentServiceData(orderElement.service.name!, orderElement.service.serviceType!.description, orderElement.totalAmount, orderElement.discountAmount == null ? 0 : orderElement.discountAmount));
                });
              });
            } 

            if (this.technicalInspection.status?.code == this.CODE_FINISHED && !this.isChangedRvsCategory) {
              this.showPaymentServicesTable();
            }
          }
        }),
      );
    })
  );

  customerDiscountSearch: OperatorFunction<string, CustomerDiscount[]> = (input$: Observable<string>) =>
    input$.pipe(
      debounceTime(300),
      filter((input) => input.length > 3),  
      switchMap(input => 
        this.nomenclatureService.filterCustomerDiscounts(input).pipe(
          catchError(() => of([])),
          shareReplay()
      )
    )
  );

  // Children
  @ViewChild('confirmTermination') confirmTerminationDialog: TemplateRef<any>;
  @ViewChild('confirmAnnul') confirmAnnul: TemplateRef<any>;
  @ViewChild('dataChange') dataChangeDialog: TemplateRef<any>;
  dialogRef: any

  constructor(
    protected router:             Router,
    public perms:                 PermissionsService,
    private dialog:               MatDialog,
    private route:                ActivatedRoute,
    private formBuilder:          FormBuilder,
    private uiEvent:              UIEventCustom,
    private translateService:     TranslateService,
    private techPointService:     TechnicalPointService,
    private techInspService:      TechnicalInspectionService,
    private nomenclatureService:  NomenclatureService,
    private contractService:      ContractService,
    private cardService:          CustomerCardService,
    private promotionService:     PromotionService
  ) {
    super(router); 
    this.technicalInspection = new TechnicalInspection;
    this.minDate = prepareDateForForm(new Date());

    this.refreshFutureTechnicalInspections();
    this.userTechnicalPointMobileIds = AuthenticationService.getEmployeeTechPointsIds();
  }

  ngOnInit() {
    this.rvsAdditional$.subscribe();
    super.ngOnInit();
    localStorage.setItem('techInspModalPopUpCounter', '0');
    this.subscriptions.add(this.additionalTechInspForm.get("isAgreeWithNotification")?.valueChanges.subscribe(value => {
      this.addRemovePhoneValidator(value);
    }));
  }

  ngOnDestroy() {
    super.ngOnDestroy();
    clearInterval(this.refreshIntervalId);
    this.rvsAdditionalSubject.unsubscribe();
    this.subscriptions.unsubscribe();
    localStorage.removeItem('techInspModalPopUpCounter');
  }

  private findLastFirstVersion(versionData: any): any {
    while (versionData.firstVersion !== null) {
      versionData = versionData.firstVersion;
    }
    return versionData;
  }

  private fillPromoRvsAndServices(promotionId: number) {
    this.promotionService.findServicesAndRvsByPromotionId(promotionId).subscribe(res => {
      if (res) {
        const rvsCodes = res.rvsCodes;
        const services = res.services;
        if (rvsCodes && Array.isArray(rvsCodes)) {
          this.promocodeAvailableCategories.push(...rvsCodes);
        }
        if (services && Array.isArray(services)) {
          this.promocodeAvailableServices.push(...services);
        }
      }
    });
  }

  addRemovePhoneValidator(isAgrNotif: boolean) {
    let phoneNumberControl = this.additionalTechInspForm.get('phoneNumber');
    if (this.annualService != null) {
      phoneNumberControl?.clearValidators();
      phoneNumberControl?.setValidators([Validators.required, Validators.maxLength(16), UtilValidators.validatePhoneNumber()]);
    } else {
      if (isAgrNotif) {
        phoneNumberControl?.clearValidators();
        phoneNumberControl?.setValidators([Validators.required, Validators.maxLength(16), UtilValidators.validatePhoneNumber()]);
      } else {
        phoneNumberControl?.clearValidators();
        phoneNumberControl?.setValidators([Validators.maxLength(16), UtilValidators.validatePhoneNumber()]);
      }
    }
    phoneNumberControl?.updateValueAndValidity();
  }

  addRemoveValidatorsWhilli() {
    let nameControl = this.additionalTechInspForm.get('name');
    if (this.isNotFromWhilli) {
      nameControl?.clearValidators();
      nameControl?.setValidators([Validators.required, Validators.maxLength(60)]);
    } else {
      nameControl?.clearValidators();
      nameControl?.setValidators([Validators.maxLength(60)]);
    }
    nameControl?.updateValueAndValidity();
  }

  openModal() {
    if (this.dialog.openDialogs.length == 0) {
      let ref = this.dialog.open(RegisterOfLegalPersonsModalComponent, modalMinWidth);
      this.subscriptions.add(ref.componentInstance.submitSubject.subscribe(result => {
        this.subjectMobileAppId = result?.mobileAppId;
        this.setSubjectData(result, true, true);
      }));

      let subjectId = this.additionalTechInspForm.get('subjectId')?.value;
      let subjectVersionData = this.additionalTechInspForm.get('subjectVersionData')?.value;
      if (subjectId != null) {
        ref.componentInstance.selectSubject(subjectId, subjectVersionData.id);
      } 
    }
  }

  clearSubject() {
    this.isHaveSubject = false;
    this.isSubjectFromPromocode = false;
    this.cleanPaymentServiceTable();
    this.additionalTechInspForm.get('subjectId')?.reset();
    this.additionalTechInspForm.get('subjectIdentNum')?.reset();
    this.additionalTechInspForm.get('subjectManager')?.reset();
    this.additionalTechInspForm.get('subjectInvoiceDdsReg')?.reset();
    this.additionalTechInspForm.get('subjectName')?.reset();
    this.additionalTechInspForm.get('subjectCity')?.reset();
    this.additionalTechInspForm.get('subjectAddress')?.reset();
    this.additionalTechInspForm.get('subjectVersionData')?.reset();
    this.additionalTechInspForm.get('customerDiscount')?.enable();
    this.additionalTechInspForm.get('customerCard')?.enable();

    this.nomenclatureService.getCustomerDiscountsBySubjectType(1).subscribe(customerDiscounts => {
      this.customerDiscounts = this.filterDiscountsByType(customerDiscounts);
      const canApply = this.applyLoyaltyCardDiscountForAllowedServices();
      if (!canApply) {
        return;
      }
      this.applyCustomerCardIfHad();
    });
  }

  async onSubmitMainForm() {
    this.findAlreadyStarted();
    this.submittedMainForm = true;
    if (this.submittedAdditionalForm) {
      if (this.mainTechAndAdditionalFormsValidation()) {
        if (this.isNotFromWhilli) {
          return;
        }
      }
    } else {
      if (this.mainTechFormValidation()) {
        return;
      }
      this.disableMainTechPointServices = true;
      this.mainTechInspForm.get('status')?.setValue(this.techInspStatuses.find(status => status.code === this.CODE_STARTED) || null);
      this.showAllFields = !this.showAllFields;
      
      if (this.mainTechInspForm.get('versionData')?.value === null || this.mainTechInspForm.get('versionData')?.value.id === undefined) {
        let resp = await this.techInspService.getTechnicalPointWorkingHourResponse(this.mainTechInspForm.get('basicTechPointService')?.value.duration, this.userTechnicalPointId).toPromise();
        let respStatus = resp.status;

        if (respStatus === this.RESP_OK) {
          this.techPointWorkingHourIds = resp.techPointWorkingHourIds;
        } else if (respStatus === this.RESP_CURRENT_SLOT_NOT_AVAILABLE) {
          this.showAllFields = false;
          this.showSlotWarningModal("messagebox.currentSlotNotAvailable");
          return;
        } else if (respStatus === this.RESP_HAS_NOT_AVAILABLE_SLOT) {
          this.showAllFields = false;
          this.showSlotWarningModal("messagebox.hasNotAvailableSlot");
          return;
        }
      }
    }

    this.loading = true;
    this.disableSaveButton = true;
    this.additionalTechInspForm.get('customerDiscount')?.enable();

    this.techInspService.saveTechnicalInspection(this.constructTechInspection(this.isDeferPayment), this.arePastLoaded).subscribe(versionData => {
      this.disableSaveButton = false;
  
      if (this.technicalInspection == null) {
        this.techInspService.findTechnicalInspectionById(versionData?.objectId).subscribe(ti =>this.currentTechnicalInspectionTimes = ti.techInspectionTimes!);
      }

      if (versionData) {
        this.mainTechInspForm.get('versionData')?.setValue(versionData);
        this.selectedTechPointServices = [];

        this.additionalServices.forEach(additionalTechPointService => {
          this.technicalInspection?.techInspectionServices?.forEach(techInspService => {
            if (additionalTechPointService.id === techInspService.technicalPointService!.id) {
              const index = this.selectedAdditionalServices.findIndex(object => object.id === additionalTechPointService.id);
              if (index === -1) {
                this.selectedAdditionalServices.push(additionalTechPointService);
              }
            }
          });
        });

        if (!this.submittedAdditionalForm) {
          this.getLineNumberByVersionId(versionData.id!);
          this.getCustomerSubscriptionByRegNumber(this.mainTechInspForm.get('regNumber')?.value);
        }

        if (this.issueInvoice) {
          this.router.navigate(['/add-edit-accounting-document'], { relativeTo: this.route, queryParams: { techInspectionId: versionData.objectId } });
        }

        if (this.submittedAdditionalForm) {
          this.uiEvent.displayUISuccess();

          if (this.viewInvoice) {
            if (this.technicalInspection.techInspectionOrders![0]?.order?.accountingDocument?.invoice != null) {
              this.router.navigate(['/add-edit-accounting-document'], {queryParams: {id: this.technicalInspection.techInspectionOrders![0]?.order?.accountingDocument?.invoice?.id}});
            } else {
              this.router.navigate(['/add-edit-accounting-document'], {queryParams: {id: this.technicalInspection.techInspectionOrders![0]?.order?.accountingDocument?.id}});
            }
          } else if (!this.issueInvoice) {
            this.router.navigate(['/add-edit-technical-inspection'], { relativeTo: this.route });
          }

          if (this.viewCreditAndDebit) {
            if (this.technicalInspection.techInspectionOrders![0]?.order?.accountingDocument?.invoice != null) { 
              this.router.navigate(['/add-edit-accounting-document'], {queryParams: {id: this.technicalInspection.techInspectionOrders![0]?.order?.accountingDocument?.id}});
            }
          }

          if (this.isBankPayment) {
            this.router.navigate(['/add-edit-accounting-document'], {queryParams: {techInspectionId: versionData.objectId}});
          }

          if (this.isDebit || this.isCredit) {
            this.router.navigate(['/add-edit-accounting-document'], 
                                {relativeTo: this.route, 
                                  queryParams: {techInspectionId: versionData.objectId, 
                                                attachedInvoiceId: this.technicalInspection.techInspectionOrders![0]?.order?.accountingDocument?.id,
                                                documentType: this.isDebit ? 'D' : 'C'}});
          }
          
          this.prepareNewTechnicialInpection();
          this.loadFutureTechInspections();
          this.loadDefaultAvailableSlotFilters();
        }
      } else {
        this.showNoAvailableSlotsModal();
      }
    }, error => {
        this.loading = false;
        displayError(error);
    });

    this.isDeferPayment = false;
  }

  async showSlotWarningModal(warnMsg: String) { 
    let dialogRef = this.dialog.open(WarningMessageboxComponent, {
      ...modalMinWidth,
      data: {
        message: warnMsg
      }
    });

    await dialogRef.afterClosed().toPromise().then((isConfirm) => {
      if (isConfirm) {
        // Do nothing
      }
    });
  }

  getLineNumberByVersionId(versionDataId: number) {
    this.techInspService.getLineNumberByVersionId(versionDataId).subscribe(lineNumber => {
      this.mainTechInspForm.get('lineNumber')?.setValue(lineNumber);
    });
  }

  getCustomerSubscriptionByRegNumber(regNumber: string) {
    this.techInspService.getCustomerSubscriptionByRegNumber(regNumber).subscribe(customerSubscription => {
      if (customerSubscription != null || customerSubscription != undefined) {
        if (customerSubscription.status.code == this.CODE_ACTIVE || customerSubscription.status.code == this.CODE_AWAITING_PAYMENT) {
          this.disableAnnualServiceAndDiscount = true;
          this.annualService = null;
        }
        this.customerSubscriptionName = customerSubscription.name;
        this.customerSubscription = customerSubscription;
        
        this.techInspService.getCustomerPhoneByCustomerRvs(customerSubscription.customerRvs.id).subscribe(resp => {
          this.additionalTechInspForm.get('phoneNumber')?.patchValue(resp.phoneNumber);
        });
      }
    });
  }

  onSubmitAdditionalFormDeferPayment() {
    this.isDeferPayment = true;
    this.onSubmitAdditionalForm();
  }

  onSubmitAdditionalFormBankPayment() {
    this.isBankPayment = true;
    this.onSubmitAdditionalForm();
  }

  onSubmitAdditionalForm() {
    this.submittedAdditionalForm = true;
    this.mainTechInspForm.get('status')?.setValue(this.techInspStatuses.find(status => status.code === this.CODE_FINISHED) || null);
    this.additionalTechInspForm.get('createdOrder')?.setValue(true);
    this.onSubmitMainForm();
  }

  onSubmitAdditionalFormWithoutPayment() {
    this.submittedAdditionalForm = true;
    this.mainTechInspForm.get('status')?.setValue(this.techInspStatuses.find(status => status.code === this.CODE_FINISHED) || null);
    this.onSubmitMainForm();
  }

  onSubmitAdditionalFormAnnulModalFutureReservation(id: number) {
    this.onSubmitAdditionalFormAnnulModal();
    this.futureTechInspId = id; 
  }

  onSubmitAdditionalFormTerminatedModal() {
    this.dialogRef  = this.dialog.open(this.confirmTerminationDialog, {
      width: '800px',
      height: '200px'
    });
  }

  onSubmitAdditionalFormAnnulModal() {
    this.dialogRef  = this.dialog.open(this.confirmAnnul, {
      width: '800px',
      height: '200px'
    });
  }

  onSubmitAdditionalFormTerminated() {
    this.dialogRef.afterClosed().subscribe((result: any) => {
      if (result) {
        if (this.futureTechInspId) {
          this.changeTechInspStatus(this.futureTechInspId, this.CODE_ANNULLED);
          this.futureTechInspId = 0;
          return;
        } 
        this.submittedAdditionalForm = true;
        this.mainTechInspForm.get('status')?.setValue(this.techInspStatuses.find(status => status.code === this.CODE_TERMINATED) || null);
        this.onSubmitMainForm();
      } else {
        this.additionalTechInspForm.get('rejectReason')?.setValue(null);
      }
    });
  }

  async onSubmitViewInvoice() {
    if (this.technicalInspection?.incomingWay?.includes('whilli')) {
      this.router.navigate(['/add-edit-accounting-document'], { queryParams: { id: this.technicalInspection.techInspectionOrders![0]?.order?.accountingDocument?.id } });
    } else {
      this.technicalInspection?.isDeferPayment ? this.isDeferPayment = true : this.isDeferPayment = false;
      this.technicalInspection?.isBankPayment ? this.isBankPayment = true : this.isBankPayment = false;
      this.submittedAdditionalForm = true;
      this.additionalTechInspForm.get('createdOrder')?.setValue(true);
      this.viewInvoice = true;
      // this.onSubmitMainForm();
      if (this.technicalInspection) {
        if (this.technicalInspection.id) {
          this.router.navigate(['/add-edit-accounting-document'], { queryParams: { id: this.technicalInspection.techInspectionOrders![0]?.order?.accountingDocument?.id } });
        }
      }
    }
  }

  onSubmitViewCreditOrDebit() {
    this.submittedAdditionalForm = true;
    this.additionalTechInspForm.get('createdOrder')?.setValue(true);
    this.viewCreditAndDebit = true;
    this.onSubmitMainForm();
  }

  onSubmitAdditionalFormAndIssueInvoice() {
    if (this.technicalInspection?.incomingWay?.includes('whilli') && this.technicalInspection.status?.code == this.CODE_FINISHED) {
      this.router.navigate(['/add-edit-accounting-document'], { relativeTo: this.route, queryParams: { techInspectionId: this.mainTechInspForm.get('id')?.value } });
    } else {
      this.technicalInspection?.isDeferPayment ? this.isDeferPayment = true : this.isDeferPayment = false;
      this.technicalInspection?.isBankPayment ? this.isBankPayment = true : this.isBankPayment = false;
      this.submittedAdditionalForm = true;
      this.mainTechInspForm.get('status')?.setValue(this.techInspStatuses.find(status => status.code === this.CODE_FINISHED) || null);
      this.additionalTechInspForm.get('createdOrder')?.setValue(true);
      this.issueInvoice = true;
      this.onSubmitMainForm();
    }
  }

  generateCreditDocument() {
    this.submittedAdditionalForm = true;
    this.mainTechInspForm.get('status')?.setValue(this.techInspStatuses.find(status => status.code === this.CODE_FINISHED) || null);
    this.additionalTechInspForm.get('createdOrder')?.setValue(true);
  
    this.onSubmitMainForm();
  }

  generateDebitDocument() {
    this.submittedAdditionalForm = true;
    this.mainTechInspForm.get('status')?.setValue(this.techInspStatuses.find(status => status.code === this.CODE_FINISHED) || null);
    this.additionalTechInspForm.get('createdOrder')?.setValue(true);
  
    this.onSubmitMainForm();
  }

  loadFutureTechInspections() {
    this.techInspService.getFutureTechnicalInspections(this.userTechnicalPointId).subscribe(futureTechInspections => {
      futureTechInspections.forEach(futureTechInsp => {
        futureTechInsp.inspectionDate = this.prepareDateForUi(futureTechInsp.inspectionDate);
      });
      this.futureTechInspections = futureTechInspections;
      this.showHideLateAndStartButton(this.futureTechInspections);
    });
  }

  changeTechInspStatus(id: number, statusCode: string): void {
    this.techInspService.changeTechInspStatus(id, statusCode).subscribe({
      next: (data) => {
        this.uiEvent.displayUISuccessChange();
        this.loadFutureTechInspections();
        this.loadDefaultAvailableSlotFilters();
      }, 
    });
  }

  checkForContract(regNumber: string) {
    if (regNumber !== '') {
      this.techInspService.checkForExistingContractByRegNumber(regNumber).subscribe(contractRvsList => {
        if (contractRvsList.length > 1) {
          this.showMultipleContractWarningModal();
        } else if (contractRvsList.length != 0) {
          const contractRvs = contractRvsList[0];
          this.mainTechInspForm.get('registrationCertNumber')?.setValue(contractRvs.registrationCertNum);
          this.additionalTechInspForm.get('contractRvsId')?.setValue(contractRvs.id);
          this.additionalTechInspForm.get('phoneNumber')?.setValue(contractRvs.phoneNumber);
          this.additionalTechInspForm.get('customerDiscount')?.setValue(contractRvs.customerDiscount);
        }
      }, error => {
        displayError(error);
        this.loading = false;  
      });
    }
  }

  async showMultipleContractWarningModal() { 
    let dialogRef = this.dialog.open(WarningMessageboxComponent, {
      ...modalMinWidth,
      data: {
        message: "messagebox.multipleCustomerDiscounts"
      }
    });

    await dialogRef.afterClosed().toPromise().then((isConfirm) => {
      if (isConfirm) {
        // Do nothing
      }
    });
  }

  onRvsCategorySelect(rvsCategory: any) {        
    this.isChangedRvsCategory = true;
    
    this.selectedAdditionalServices = [];
    this.rvsAdditionalSubject.next(rvsCategory);

    if (this.subjectMobileAppId != null) {
      this.subjectHasContract(this.subjectMobileAppId, true);
    }
    this.hasPromocode = this.promocodeAvailableCategories.includes(rvsCategory.code);
    this.cleanPaymentServiceTable();
  }

  onBasicTechPointServiceSelect() {
    const value = this.mainTechInspForm.get('basicTechPointService')?.value;
    if (value) {
      this.hasPromocode = this.promocodeAvailableServices.includes(value.serviceName);
    }

    if (this.subjectMobileAppId != null) {
      this.subjectHasContract(this.subjectMobileAppId, true);
      const canApply = this.applyLoyaltyCardDiscountForAllowedServices();
      if (!canApply) {
        this.resetCustomerCardAndDiscount();
        return;
      }
    }
   
    this.cleanPaymentServiceTable();
    const canApply = this.applyLoyaltyCardDiscountForAllowedServices();
    if (!canApply) {
      this.resetCustomerCardAndDiscount();
      return;
    }
  }

  private resetCustomerCardAndDiscount() {
    this.additionalTechInspForm.get('customerCard')?.setValue('');
    this.additionalTechInspForm.get('customerDiscount')?.setValue('');
  }

  onCustomerDiscountSelect() {
    this.cleanPaymentServiceTable();

    if (this.lastSelectedDiscountByCard) {
      const discount = this.additionalTechInspForm.get('customerDiscount')?.value as CustomerDiscount;
      if (discount.discountName !== this.lastSelectedDiscountByCard) {
        this.customerCard = null;
        this.additionalTechInspForm.get('customerCard')?.setValue('');
      } 
    }
  }

  onRejectReasonSelect(rejectReason: any) {
    if (rejectReason == '') {
      this.additionalTechInspForm.get('rejectReason')?.patchValue(null);
    }
  }

  onChangeAdditionalService(event: any, service: TechPointService) {
    if (event.target.checked) {
      this.selectedAdditionalServices.push(service);
    } else {
      let index = this.selectedAdditionalServices.indexOf(service);
      this.selectedAdditionalServices.splice(index,1);
    }

    this.cleanPaymentServiceTable();
  }

  onChangeAnnualService(event: any, service: TechPointService) {
    this.cleanPaymentServiceTable();
    if (this.annualService != null && this.annualService.id === service.id) {
      this.annualService = null;
      return;
    }
    
    this.annualService = service;
    this.getServicesMobileAppIdsForCard(this.annualService.serviceId!);
    this.additionalTechInspForm.get('customerDiscount')?.patchValue('');
    this.addRemovePhoneValidator(this.additionalTechInspForm.get("isAgreeWithNotification")?.value);
  }

  // This method cleans the service table below.
  cleanPaymentServiceTable() {
    this.submittedAdditionalForm = false;
    this.isVisiblePaymentServicesTable = false;
    this.paymentServiceTableData = [];

    if (this.additionalTechInspForm.get('customerDiscount')?.value || this.technicalInspection?.techInspectionOrders == undefined 
        || this.technicalInspection?.techInspectionOrders![0]?.order?.promotion.id == null) {
      this.paymentServiceConvertedData = [];
    } else if (this.technicalInspection?.techInspectionOrders != undefined && this.technicalInspection?.techInspectionOrders![0]?.order?.promotion.id != null) {    
      // When we have a promotion we do not clear this service.
      let servicesWithPromotions: PaymentServiceData[] = [];
      this.paymentServiceConvertedData.forEach(service => {
        if (this.showAllFields == true) {
          if (service.calculatedDiscount != 0) {
            if (this.selectedAdditionalServices.length > 0) {
              this.selectedAdditionalServices.forEach(addService => {
                if (addService.serviceName == service.serviceName) {
                  servicesWithPromotions.push(service);
                }
              })
            }
            if (service.serviceType == this.BASIC_SERVICE_TYPE) {
              servicesWithPromotions.push(service);
            }
          } 
        } else {
          if (service.calculatedDiscount && service?.calculatedDiscount != 0) {
            servicesWithPromotions.push(service);
          } else {
            servicesWithPromotions = [];
          }
        }
      })
      
      this.paymentServiceConvertedData = [];
  
      servicesWithPromotions?.forEach(promotionService => {
        if (promotionService.calculatedDiscount != 0) {
          let basicService = this.mainTechInspForm.get('basicTechPointService')?.value;
          if(basicService?.serviceName != promotionService?.serviceName && promotionService?.serviceType == this.BASIC_SERVICE_TYPE) {
            this.paymentServiceConvertedData.push(createPaymentServiceData(basicService.serviceName, basicService.serviceType, 
                                                                           basicService.amountDds, basicService.calculatedDiscount));
          } else {
            this.paymentServiceConvertedData.push(promotionService);
          } 
        }
       
      });
      servicesWithPromotions = [];
    }
  }

  async showPaymentServicesTable() {
    this.calculatePaidAmountServices();
    // if annualService != null to get corect services for this card
    if (this.annualService != null) {
      this.getServicesMobileAppIdsForCard(this.annualService!.serviceId!);
    }
    this.submittedAdditionalForm = true;
    if (this.mainTechAndAdditionalFormsValidation()) {
      return;
    } 

    let mainData = this.mainTechInspForm.value;
    let additionalData = this.additionalTechInspForm.value;
    if (this.annualService != null && additionalData.phoneNumber != null && additionalData.phoneNumber != '' 
          && this.technicalInspection?.status?.code !== this.CODE_FINISHED) {
      let hasValidSubscription = await this.techInspService.checkForValidSubscription(additionalData.phoneNumber, mainData.regNumber, mainData.registrationCertNumber, this.annualService!.serviceId!).toPromise();

      if (hasValidSubscription) {
        let dialogRefWarn = this.dialog.open(WarningMessageboxComponent, {
          ...modalMinWidth,
          data: {
            message: "messagebox.validSubscription"
          }
        });
        await dialogRefWarn.afterClosed().toPromise();
        this.annualService = null;
      } else {
        let dialogRefWarn = this.dialog.open(YesNoMessageboxComponent, {
          ...modalMinWidth,
          data: {
            isWarning: false,
            message: "messagebox.cardPurchase"
          }
        });
        let isConfirmed = await dialogRefWarn.afterClosed().toPromise(); 
        if (!isConfirmed) {
          this.annualService = null;
        }
      }
    }

    this.isVisiblePaymentServicesTable = true;

    if (this.isChangedRvsCategory) {
      this.paymentServiceConvertedData = [];
    }
    
    if (this.selectedAdditionalServices.length > 0) {
      this.selectedAdditionalServices.forEach(service => this.paymentServiceTableData.push(service));
    }

    if (this.annualService != null) {
      this.paymentServiceTableData.push(this.annualService);
    }

    let mainService = this.mainForm.basicTechPointService.value;
    if (mainService && typeof mainService.amountDds === 'number') {
        mainService.amountDds = Number.parseFloat(mainService.amountDds.toFixed(2));
    }
    this.paymentServiceTableData.push(mainService);

    let orderElIds: number[] = [];

    this.paymentServiceTableData.forEach(service => {
      if (!this.additionalTechInspForm.get('customerDiscount')?.value && this.technicalInspection?.techInspectionOrders != undefined 
         && this.technicalInspection?.techInspectionOrders![0]?.order?.promotion?.id != null) {

        this.technicalInspection?.techInspectionOrders![0]?.order?.orderElements?.forEach(orderEl => {
          orderElIds.push(orderEl.service.mobileAppId);
          if (service.serviceMobileAppId == orderEl.service.mobileAppId){
            if (orderEl.discountAmount == 0){
              this.currentSelectedServiceMobileAppIds.push(service.serviceMobileAppId!);
            } 
          }})

          if (!orderElIds.includes(service.serviceMobileAppId!)) {
            this.currentSelectedServiceMobileAppIds.push(service.serviceMobileAppId!);
          }
      } else {
        this.currentSelectedServiceMobileAppIds.push(service.serviceMobileAppId!);
      }     
    });

    let result: any[] = []; 

    if (this.technicalInspection != null && this.technicalInspection.inspectionDate && this.currentSelectedServiceMobileAppIds.length != 0) {
      result = await this.techInspService.getTechPointServicePricesFromFunction(this.techPointWorkingHourIds[0], this.userTechnicalPointId, mainData.rvsCategory.code, this.currentSelectedServiceMobileAppIds, this.technicalInspection.inspectionDate!).toPromise();
      this.currentSelectedServiceMobileAppIds = [];
    } else {
      if (this.currentSelectedServiceMobileAppIds.length != 0) {
        result = await this.techInspService.getTechPointServicePricesFromFunction(this.techPointWorkingHourIds[0], this.userTechnicalPointId, mainData.rvsCategory.code, this.currentSelectedServiceMobileAppIds, new Date().toISOString().split('T')[0].split("-").reverse().join("-")).toPromise();
        this.currentSelectedServiceMobileAppIds = [];
      }
    }

    this.paymentServiceTableData.forEach(service1 => {
      result.forEach(service2 => {
        if (service1.serviceMobileAppId === service2.serviceMobileAppId) {
          service1.amountDds = service2.discountedPrice;
          service1.calculatedDiscount = service2.discountedValue;
        }
      });
    });

    if (this.annualService != null) {
      this.checkForServicesInCard();
    } else if(this.customerSubscription != null && this.customerSubscription != undefined && this.technicalInspection?.status?.code !== this.CODE_FINISHED) {
      this.checkForServicesInExistingCard();
    } else {
      this.paymentServiceTableData.forEach(service => {
        const index = this.paymentServiceConvertedData.findIndex(object => object.serviceName === service.serviceName);
       
        if (index === -1) {
          this.paymentServiceConvertedData.push(createPaymentServiceData(service.serviceName!, service.serviceType!, service.amountDds!, service.calculatedDiscount!));
        }
      });
    }

    if (this.additionalForm.customerDiscount.value !== '') {
      this.calculateServicesDiscount();
    }
    
    this.isChangedRvsCategory = false;
    
    if ((this.customerSubscriptionName != null || this.customerSubscriptionName != undefined) && this.technicalInspection.status?.code == this.CODE_FINISHED) {
      this.canEdit = false;
    } else {
      this.canEdit = true;
    }

    
    if (!this.technicalInspection || !this.technicalInspection.techInspectionOrders?.length) return;
    
    if (this.technicalInspection.techInspectionOrders![0].order?.status.code === this.CODE_PAID) {
      let serviceTableTotalAmount = 0;
      this.paymentServiceConvertedData.forEach(service => {
        serviceTableTotalAmount += service?.amountDds;
      });

      if (this.technicalInspection?.techInspectionOrders![0]?.order?.promotion?.id) {
        this.currentAmountServices = serviceTableTotalAmount || 0;
        this.calculatePaidAmountServicesFromTable();
      }

      if (Number(serviceTableTotalAmount.toFixed(2)) < this.technicalInspection.techInspectionOrders![0].order?.totalAmount 
          && this.technicalInspection?.status?.code != this.CODE_FINISHED) {
        this.isCredit = true;
        this.isDebit = false;
      } else if (Number(serviceTableTotalAmount.toFixed(2)) > this.technicalInspection.techInspectionOrders![0].order?.totalAmount 
        && this.technicalInspection?.status?.code != this.CODE_FINISHED) {
        this.isDebit = true;
        this.isCredit = false;
      } else {
        this.isDebit = false;
        this.isCredit = false;
      }
    }
  }

  private checkForServicesInExistingCard() {
    this.paymentServiceConvertedData = [];
    this.paymentServiceTableData.forEach(service => {
      if (this.checkIfServiceIsInTheExistingCard(service.serviceMobileAppId!)) {
        this.paymentServiceConvertedData.push(createPaymentServiceData(service.serviceName!, service.serviceType!, 0, null));
      } else {
        this.paymentServiceConvertedData.push(createPaymentServiceData(service.serviceName!, service.serviceType!, Number.parseFloat(service.amountDds!.toFixed(2)), service.calculatedDiscount!));
      }
    });
  }

  checkIfServiceIsInTheExistingCard(mobileAppId:number) {
    const subscrServices: CustomerSubscriptionService[] = this.customerSubscription!.customerSubscriptionServices;
    for (let index = 0; index < subscrServices.length; index++) {
      const subscrServ = subscrServices[index];

      if (subscrServ.serviceAddInfoItem.childService!.mobileAppId === mobileAppId && subscrServ.isAvailable) {
        return true;
      }
    }
    return false;
  }

  private checkForServicesInCard() {
    this.paymentServiceConvertedData = [];
    this.paymentServiceTableData.forEach(service => {
      if (this.checkIfServiceIsInTheCard(service.serviceMobileAppId!)) {
        this.paymentServiceConvertedData.push(createPaymentServiceData(service.serviceName!, service.serviceType!, 0, null));
      } else {
        this.paymentServiceConvertedData.push(createPaymentServiceData(service.serviceName!, service.serviceType!, Number.parseFloat(service.amountDds!.toFixed(2)), service.calculatedDiscount!));
      }
    });
  }

  checkIfServiceIsInTheCard(mobileAppId:number) {
    return this.servicesMobileAppIdsForCard!.some(mobAppId => mobAppId === mobileAppId);
  }

  private calculateServicesDiscount() {
    let selectedCustomerDiscount = this.additionalForm.customerDiscount.value;
    
    if (selectedCustomerDiscount != null) {
      if (selectedCustomerDiscount.priceAttribute.kind === this.DISCOUNT_PERCENTAGE) {
        this.paymentServiceTableData.forEach(service => {
          let currentCalculateDiscount = service.calculatedDiscount;
          service.calculatedDiscount = Math.round(((service.amountDds! * selectedCustomerDiscount.priceAttribute.amount / 100)) * 100) / 100;
          service.amountDds! -= service.calculatedDiscount;
          if (service.amountDds) {
            service.amountDds = Number.parseFloat(service.amountDds?.toFixed(2));
          }
          service.calculatedDiscount += currentCalculateDiscount!;
        });
      } 
  
      if (selectedCustomerDiscount.priceAttribute.kind === this.DISCOUNT_VALUE) {
        let totalAmountFromServices = 0;
        this.paymentServiceTableData.forEach(service => {
          totalAmountFromServices+=service.amountDds!;
        });

        this.paymentServiceTableData.forEach(service => {
          let calculatedPercantageFromTotal = Math.round((service.amountDds! / totalAmountFromServices * 100) * 100) / 100;
          let calcValue  = Math.round((selectedCustomerDiscount.priceAttribute.amountDds * calculatedPercantageFromTotal / 100) * 100) / 100;
          service.calculatedDiscount! += calcValue;
          service.amountDds! -= calcValue;
        });
      }

      this.paymentServiceTableData.forEach(s => {
        if (s.serviceName !== this.BASIC_SERVICE_NAME) {
          const basicServiceIndex = this.paymentServiceTableData.findIndex(entity => entity.serviceName === this.BASIC_SERVICE_NAME);
      
          if (basicServiceIndex !== -1 && this.paymentServiceTableData[basicServiceIndex]) {
            if (selectedCustomerDiscount.priceAttribute.kind === this.DISCOUNT_VALUE) {
              const basicServiceEntity = this.paymentServiceTableData[basicServiceIndex];
              basicServiceEntity.calculatedDiscount = basicServiceEntity.calculatedDiscount || 0;
              basicServiceEntity.calculatedDiscount += s.calculatedDiscount || 0;
              if (basicServiceEntity.amountDds != undefined) {
                basicServiceEntity.amountDds -= s.calculatedDiscount || 0; 
              }
            }
            if (s.amountDds != undefined) {
              s.amountDds += s.calculatedDiscount || 0;
            }
            s.calculatedDiscount = 0;
          }
        }
      });

      if (selectedCustomerDiscount.priceAttribute.kind === this.DISCOUNT_VALUE) {
        const basicServiceIndex = this.paymentServiceTableData.findIndex(entity => entity.serviceName === this.BASIC_SERVICE_NAME);
        if (basicServiceIndex !== -1) {
          const basicServiceEntity = this.paymentServiceTableData[basicServiceIndex];
          const discountDiff = selectedCustomerDiscount.priceAttributeAmountDds - (basicServiceEntity.calculatedDiscount || 0);
          
          if (discountDiff !== 0) {
            basicServiceEntity.amountDds = (basicServiceEntity.amountDds || 0) + discountDiff;
            basicServiceEntity.calculatedDiscount = selectedCustomerDiscount.priceAttributeAmountDds;
          }
      
          const originalBasicIndex = this.basicServicesCopy.findIndex(entity => entity.serviceName === basicServiceEntity.serviceName);
          if (originalBasicIndex !== -1) {
            const originalBasicEntity = this.basicServicesCopy[originalBasicIndex];
            
            if (originalBasicEntity.amountDds !== basicServiceEntity.amountDds) {
              basicServiceEntity.amountDds = (originalBasicEntity.amountDds || 0) - (basicServiceEntity.calculatedDiscount || 0);
            }
          }
        }
      }

      this.paymentServiceConvertedData = [];
      this.paymentServiceTableData.forEach(service => {
        const index = this.paymentServiceConvertedData.findIndex(object => object.serviceName === service.serviceName);
        if (index === -1) {
          this.paymentServiceConvertedData.push(createPaymentServiceData(service.serviceName!, service.serviceType!, Number.parseFloat(service.amountDds!.toFixed(2)), service.calculatedDiscount!));
        }
      });
      this.calculatePaidAmountServices();
      return;
    } 

    if (this.technicalInspection && this.technicalInspection.promotionId) {
      this.paymentServiceConvertedData = [];
      this.orderElementsByPromotionCode.forEach(ol => {
        this.paymentServiceConvertedData.push(createPaymentServiceData(ol.service.name!, ol.service.serviceType!.description, ol.totalAmount!, ol.discountAmount!));
      });
    }
    this.calculatePaidAmountServices();
  }

  private constructTechInspection(isDeferPayment?: boolean) {
    this.mainTechInspForm.get('incomingWay')?.setValue(this.INCOMING_WAY_TP);
    this.mainTechInspForm.get('inspectionDate')?.patchValue(this.prepareDateForDb(this.mainTechInspForm.get('inspectionDate')?.value));
    this.mainTechInspForm.get('fromErp')?.patchValue(true);

    let mainData = this.mainTechInspForm.value;
    let additionalData = this.additionalTechInspForm.value;

    if (this.technicalInspection !== null) {
      this.technicalInspectionTimes
    }

    if (this.selectedAdditionalServices.length > 0) {
      this.selectedAdditionalServices?.forEach(service => {
        if (!this.additionalTechInspForm.get('customerDiscount')?.value && this.technicalInspection?.techInspectionOrders != undefined && this.technicalInspection?.techInspectionOrders![0]?.order?.promotion?.id != null) { 
          if (this.constructTechPointServiceFromOrderElement(service) != null) {
            this.selectedTechPointServices.push(this.constructTechPointServiceFromOrderElement(service));
          } else {
            this.selectedTechPointServices.push(service);
          }
        } else {
          this.selectedTechPointServices.push(service);
        }
      });
    }

    if (this.annualService != null && this.submittedAdditionalForm) {
      this.selectedTechPointServices.push(this.annualService);
    }

    if (this.technicalInspection?.techInspectionOrders != undefined && !this.additionalTechInspForm.get('customerDiscount')?.value) {
      if (this.technicalInspection?.techInspectionOrders![0]?.order?.promotion?.id != null) {
        if (this.constructTechPointServiceFromOrderElement(mainData.basicTechPointService) != null) {
          this.selectedTechPointServices.push(this.constructTechPointServiceFromOrderElement(mainData.basicTechPointService));
        } else {
          this.selectedTechPointServices.push(mainData.basicTechPointService);
        }
      } else {
        this.selectedTechPointServices.push(mainData.basicTechPointService);
      }
    } else {
      this.selectedTechPointServices.push(mainData.basicTechPointService);
    }
    
    let isAgree = additionalData.isAgreeWithNotification;
    if (isAgree === null || isAgree === undefined) {
      isAgree = true;
    }

    const services = this.constructServices(this.selectedTechPointServices);

    if (!additionalData.customerDiscount) {
      this.customerCard = null;
    }

    const obj = {
      id:                      mainData.id                            || null,
      status:                  mainData.status                        || null,
      regNumber:               mainData.regNumber                     || null,
      customerRvsId:           mainData.customerRvsId                 || null,
      registrationCertNumber:  mainData.registrationCertNumber        || null,
      rvsCategory:             mainData.rvsCategory                   || null,
      versionData:             mainData.versionData                   || null,
      inspectionDate:          mainData.inspectionDate                || null,
      technicalPointId:        this.userTechnicalPointId              || null,
      incomingWay:             mainData.incomingWay                   || null,
      fromErp:                 mainData.fromErp                       || null,
      email:                   additionalData.email                   || null,
      name:                    additionalData.name                    || null,
      contractRvsId:           additionalData.contractRvsId           || null,
      phoneNumber:             additionalData.phoneNumber             || null,
      rejectReason:            additionalData.rejectReason            || null,
      customerDiscount:        additionalData.customerDiscount        || null,
      createdOrder:            additionalData.createdOrder            || null,
      issueInvoice:            additionalData.issueInvoice            || null,
      isAgreeWithNotification: isAgree,
      isEmployee:              additionalData.isEmployee              || false,
      customerCard:            this.customerCard                      || null,
      promotionId:             additionalData.promotionId             || null,
      techPointServices:       services,
      techInspectionOrders:    this.constructTechInspOrders(this.technicalInspection!),
      techInspectionTimes:     this.constructTimes(this.technicalInspection!),
      invoiceSubjectVersion:   this.constructSubject(additionalData),
      techPointWorkingHourIds: this.constructTechPointWorkingHours(),
      isDeferPayment:          isDeferPayment,
      isBankPayment:           this.isBankPayment,
      skipSubscribtion:        false,
      wantsMarketingMessages:  this.wantMarketingNotification
    }

    return obj;
  }

  private constructTechPointServiceFromOrderElement(serviceData: any) {
    let isConstructed: boolean = false;
    this.technicalInspection?.techInspectionOrders![0]?.order?.orderElements?.forEach(element => {
      if(element.service.mobileAppId == serviceData.serviceMobileAppId) {
        if(element.discountAmount != 0) {
          this.priceAttribute = {
            id: undefined, 
            amount: element.amount,
            amountDds: element.totalAmount,
            ddsValue: 20
          }
          isConstructed = true;
        }
      } 
    })

    if(isConstructed) {
      return {
        id:                 serviceData.id                 || null,
        status:             serviceData.status             || null,
        isValid:            serviceData.isValid            || null,
        rvsService:         serviceData.rvsService         || null,
        duration:           serviceData.duration           || null,
        validFrom:          serviceData.validFrom          || null,
        validTo:            serviceData.validTo            || null,
        serviceType:        serviceData.serviceType        || null,
        serviceId:          serviceData.serviceId          || null,
        serviceMobileAppId: serviceData.serviceMobileAppId || null,
        priceAttribute:     this.priceAttribute            || null,
        calculatedDiscount: serviceData.calculatedDiscount || null
      }
    } else {
      return null;    
    }
  }

  private constructTimes(techInspection: TechnicalInspection) {
    if (techInspection === null || techInspection.techInspectionTimes?.length === 0) {
      return [];
    }
    return techInspection.techInspectionTimes;
  }

  private constructTechInspOrders(techInspection: TechnicalInspection) {
    if (techInspection === null || techInspection.techInspectionOrders?.length === 0) {
      return [];
    }
    return techInspection.techInspectionOrders;
  }

  private constructTechPointWorkingHours() {
    if (this.techPointWorkingHourIds === null || this.techPointWorkingHourIds?.length === 0) {
      return [];
    }

    return this.techPointWorkingHourIds;
  }

  private constructServices(data: any[]) {
    return data.filter(serviceData => serviceData.id !== null || serviceData.isValid).map(serviceData => {
      return {
        id:                 serviceData.id                 || null,
        status:             serviceData.status             || null,
        isValid:            serviceData.isValid            || null,
        rvsService:         serviceData.rvsService         || null,
        duration:           serviceData.duration           || null,
        validFrom:          serviceData.validFrom          || null,
        validTo:            serviceData.validTo            || null,
        serviceType:        serviceData.serviceType        || null,
        serviceId:          serviceData.serviceId          || null,
        serviceMobileAppId: serviceData.serviceMobileAppId || null,
        priceAttribute:     serviceData.priceAttribute     || null,
        calculatedDiscount: serviceData.calculatedDiscount || null
      }
    });
  }

  private constructSubject(data: any) {
    if (data.subjectId == null) return undefined;

    return {
      id:          data.subjectId,
      fullName:    data.subjectName,
      identNum:    data.subjectIdentNum,
      versionData: data.subjectVersionData
    }
  }

  private setSubjectData(subject: SubjectVersion | ConsiceSubjectVersion | undefined, isFromModal: boolean, checkForContract: boolean) {
    if (subject == null) return;
    this.isHaveSubject = true;

    // this.additionalTechInspForm.get('customerDiscount')?.patchValue(null);

    this.additionalTechInspForm.get('subjectId')?.patchValue(subject?.id);
    this.additionalTechInspForm.get('subjectIdentNum')?.patchValue(subject?.identNum);
    this.additionalTechInspForm.get('subjectManager')?.patchValue(subject?.manager);
    this.additionalTechInspForm.get('subjectInvoiceDdsReg')?.patchValue(subject?.invoiceDdsRegistration);
    this.additionalTechInspForm.get('subjectName')?.patchValue(subject?.fullName);
    this.additionalTechInspForm.get('subjectCity')?.patchValue(subject?.city);
    this.additionalTechInspForm.get('subjectAddress')?.patchValue(subject?.address);
    this.additionalTechInspForm.get('subjectVersionData')?.patchValue(subject?.versionData);
    
    if (checkForContract) {
      this.subjectHasContract(subject?.mobileAppId, isFromModal);
    }
  }

  private setSubjectDataWithoutContract(subject: SubjectVersion | ConsiceSubjectVersion | undefined, isFromModal: boolean) {
    if (subject == null) return;

    this.isHaveSubject = true;
    this.additionalTechInspForm.get('subjectId')?.patchValue(subject?.id);
    this.additionalTechInspForm.get('subjectIdentNum')?.patchValue(subject?.identNum);
    this.additionalTechInspForm.get('subjectManager')?.patchValue(subject?.manager);
    this.additionalTechInspForm.get('subjectInvoiceDdsReg')?.patchValue(subject?.invoiceDdsRegistration);
    this.additionalTechInspForm.get('subjectName')?.patchValue(subject?.fullName);
    this.additionalTechInspForm.get('subjectCity')?.patchValue(subject?.city);
    this.additionalTechInspForm.get('subjectAddress')?.patchValue(subject?.address);
    this.additionalTechInspForm.get('subjectVersionData')?.patchValue(subject?.versionData);
  }

  subjectHasContract(mobileAppId: number | undefined, isFromModal: boolean) {
    this.cleanPaymentServiceTable();

    this.contractService.findContractDiscountBySubjectId(mobileAppId, this.userTechnicalPointMobileIds[0], 
                                                         this.mainTechInspForm.get('basicTechPointService')?.value.serviceMobileAppId,
                                                         this.mainTechInspForm.get('rvsCategory')?.value.id).subscribe(customerDiscounts => {
      this.additionalTechInspForm.get('customerCard')?.enable();
      if (customerDiscounts?.length == 0 && isFromModal) {
        this.additionalTechInspForm.get('customerDiscount')?.patchValue(null);
        this.nomenclatureService.getCustomerDiscountsBySubjectType(1).subscribe(customerDiscounts => {
          this.customerDiscounts = this.filterDiscountsByType(customerDiscounts);
          const canApply = this.applyLoyaltyCardDiscountForAllowedServices();
          if (!canApply) {
            return;
          }
          this.applyCustomerCardIfHad();
        });
        this.additionalTechInspForm.get('customerDiscount')?.enable();
      } else if (customerDiscounts?.length == 1) {
        this.nomenclatureService.getCustomerDiscountsBySubjectType(1).subscribe(customerDiscounts => {
          this.customerDiscounts = this.filterDiscountsByType(customerDiscounts);
          const canApply = this.applyLoyaltyCardDiscountForAllowedServices();
          if (!canApply) {
            return;
          }
          this.applyCustomerCardIfHad();
        });
        if (customerDiscounts.length > 0) {
          this.additionalTechInspForm.get('customerDiscount')?.patchValue(customerDiscounts[0]);
          this.additionalTechInspForm.get('customerDiscount')?.disable();
          customerDiscounts.splice(1);
        }
        const canApply = this.applyLoyaltyCardDiscountForAllowedServices();
        if (!canApply) {
          return;
        }
        this.applyCustomerCardIfHad();
      } else if (customerDiscounts?.length > 1) {
        this.customerDiscounts = this.filterDiscountsByType(customerDiscounts);
        this.additionalTechInspForm.get('customerDiscount')?.enable();
        const canApply = this.applyLoyaltyCardDiscountForAllowedServices();
        if (!canApply) {
          return;
        }
        this.applyCustomerCardIfHad();
      }
    });
  }

  private applyCustomerCardIfHad() {
    const customerCard = this.additionalTechInspForm.get('customerCard')?.value;
    if (customerCard) {
      this.cardService.getLoyalComboByCard(customerCard).subscribe(data => {
        if (!data) return;
        const loyalCombo = data as LoyalCombo;
        this.customerCard = loyalCombo.customerCard;
        if (loyalCombo.customerDiscount) {
          this.lastSelectedDiscountByCard = loyalCombo.customerDiscount.discountName;
          this.additionalTechInspForm.get('customerDiscount')?.setValue(loyalCombo.customerDiscount);
          this.customerDiscounts.push(loyalCombo.customerDiscount);
          this.customerDiscounts = Array.from(new Set(this.customerDiscounts));
        }
      });
    }
  }

  private availableSlotFormValidation() {
    return this.availableSlotForm.invalid || this.isValidSlotFormRegNum || this.isInvalidRegNumberForForeigner 
      || this.isInvalidRegNumberForDiplomatic || this.isInvalidRegNumberForElectric;
  }

  private mainTechFormValidation() {
    return this.mainTechInspForm.invalid || this.isValidMainFormRegNum || this.isInvalidRegNumberForForeigner 
      || this.isInvalidRegNumberForDiplomatic || this.isInvalidRegNumberForElectric;
  }

  private mainTechAndAdditionalFormsValidation() {
    return this.mainTechInspForm.invalid || this.additionalTechInspForm.invalid || this.isValidMainFormRegNum;
  }

  checkId(id:number) {
    if (this.technicalInspection == null || this.technicalInspection.techInspectionServices == null) {
      return false;
    }
    return this.technicalInspection && this.technicalInspection.techInspectionServices!.some(s => s.technicalPointService!.id === id);
  }

  compareById(optionOne: any, optionTwo: any) {
    return optionOne && optionTwo && optionOne.id === optionTwo.id;
  }

  getServicesMobileAppIdsForCard(serviceId: number) {
    if (serviceId != null) {
      this.techInspService.getServicesMobileAppIdsForCard(serviceId).subscribe(servicesMobileAppIdsForCard => {
        this.servicesMobileAppIdsForCard = servicesMobileAppIdsForCard;
        this.checkForServicesInCard();
      });
    }
  }

  getGroupedTechnicalPointWorkingHours(availableSlotData: any, arePastLoaded: boolean) {
    const dateStr = prepareDateForDb(availableSlotData.forDate);

    this.loadingQuickReservation = true;
    this.techInspService.getGroupedTechnicalPointWorkingHours(dateStr, availableSlotData.line.id, availableSlotData.rvsCategory.id, 
        availableSlotData.basicTechPointService.duration, this.userTechnicalPointId, arePastLoaded).subscribe(groupedTechPointWorkingHours => {
      this.groupedTechPointWorkingHours = groupedTechPointWorkingHours;
      this.availableSlotForm.get('groupedTechPointWorkingHours')?.patchValue(this.groupedTechPointWorkingHours[0]);
      this.loadingQuickReservation = false;
    });
  }

  loadOldReservations(arePastLoaded: boolean, isPastClicked: boolean) {
    this.arePastLoaded = !arePastLoaded;
    this.isPastClicked = !isPastClicked;
    this.getGroupedTechnicalPointWorkingHours(this.availableSlotForm.value, this.arePastLoaded);
  }

  onAvailableSlotDataChange() {
    this.getGroupedTechnicalPointWorkingHours(this.availableSlotForm.value, this.arePastLoaded);
  }

  onAvailableSlotRvsCategoryChange() {
    let data = this.availableSlotForm.value;
    this.techInspService.getBasicRvsCategoryServices(data.rvsCategory, this.userTechnicalPointId).subscribe(basicServices => {
      this.basicServicesForFirstAvailableSlot = basicServices;
      let fetchedBasicTechPointService = this.basicServicesForFirstAvailableSlot.find(service => service.serviceName === this.BASIC_SERVICE_NAME) || this.basicServicesForFirstAvailableSlot[0];
      this.availableSlotForm.get('forDate')?.patchValue(data.forDate);
      this.availableSlotForm.get('line')?.patchValue(data.line);
      this.availableSlotForm.get('rvsCategory')?.patchValue(data.rvsCategory);
      this.availableSlotForm.get('basicTechPointService')?.patchValue(fetchedBasicTechPointService);
      this.getGroupedTechnicalPointWorkingHours(this.availableSlotForm.value, this.arePastLoaded);
    });
  }

  onSubmitAvailableSlotForm() {
    this.submittedAvailableSlotForm = true;
    if (this.availableSlotFormValidation()) {
      return;
    }

    this.loading = true;
    let availableSlotData = this.availableSlotForm.value;
    
    this.techInspService.saveAvalableSlot(this.constructAvailableSlotData(availableSlotData), this.arePastLoaded).subscribe({
      next: (result) => {
        this.submittedAvailableSlotForm = false;
        if (result === 0) {
           this.showBusySlotWarningModal(availableSlotData);
        } else {
          this.uiEvent.displayUISuccess();

          this.loadFutureTechInspections();
          this.loadDefaultAvailableSlotFilters();
        }
      }  
    });
  }

  async showBusySlotWarningModal(availableSlotData: any) { 
    let dialogRef = this.dialog.open(WarningMessageboxComponent, {
      ...modalMinWidth,
      data: {
        message: "messagebox.busySlot"
      }
    });

    await dialogRef.afterClosed().toPromise().then((isConfirm) => {
      if (isConfirm) {
        this.loadFutureTechInspections();
        this.loadAvailableSlotWithCurrentFilters(availableSlotData);
      }
    });
  }

  async showNoAvailableSlotsModal() { 
    let dialogRef = this.dialog.open(WarningMessageboxComponent, {
      ...modalMinWidth,
      data: {
        message: "messagebox.noAvailableSlots"
      }
    });

    await dialogRef.afterClosed().toPromise().then((isConfirm) => {
      if (isConfirm) {
        this.prepareNewTechnicialInpection();
      }
    });
  }

  private constructAvailableSlotData(availableSlotData: any) {
    const dateStr = prepareDateForDb(availableSlotData.forDate);

    return {
      forDate:                      dateStr,
      line:                         availableSlotData.line                         || null,
      regNumber:                    availableSlotData.regNumber                    || null,
      phoneNumber:                  availableSlotData.phoneNumber                  || null,
      rvsCategory:                  availableSlotData.rvsCategory                  || null,
      techPointService:             availableSlotData.basicTechPointService        || null,
      groupedTechPointWorkingHours: availableSlotData.groupedTechPointWorkingHours || null
    }
  }

  private loadDefaultAvailableSlotFilters() {
    this.defRvsCategory = this.rvsCategories.find(category => category.code === this.RVS_CATEGORY_CODE) || null;
    this.availableSlotForm.get('forDate')?.patchValue(prepareDateForForm(new Date()));
    this.availableSlotForm.get('regNumber')?.patchValue("");
    this.availableSlotForm.get('rvsCategory')?.patchValue(this.defRvsCategory);
    this.availableSlotForm.get('line')?.patchValue(this.technicalPointLines[0]);
    
    this.techInspService.getBasicRvsCategoryServices(this.defRvsCategory, this.userTechnicalPointId).subscribe(basicServices => {
      this.basicServicesForFirstAvailableSlot = basicServices;
      let fetchedBasicTechPointService = this.basicServicesForFirstAvailableSlot.find(service => service.serviceName === this.BASIC_SERVICE_NAME) || null;
      this.availableSlotForm.get('basicTechPointService')?.patchValue(fetchedBasicTechPointService);
      this.getGroupedTechnicalPointWorkingHours(this.availableSlotForm.value, this.arePastLoaded);
    });
  }

  private loadAvailableSlotWithCurrentFilters(availableSlotData: any) {
    this.availableSlotForm.get('forDate')?.patchValue(availableSlotData.forDate);
    this.availableSlotForm.get('regNumber')?.patchValue(availableSlotData.regNumber);
    this.availableSlotForm.get('rvsCategory')?.patchValue(availableSlotData.rvsCategory);
    this.availableSlotForm.get('line')?.patchValue(availableSlotData.line);
    this.availableSlotForm.get('basicTechPointService')?.patchValue(availableSlotData.basicTechPointService);
    this.getGroupedTechnicalPointWorkingHours(this.availableSlotForm.value, this.arePastLoaded);
  }

  async startTechInspection(futTechInsp: any) {
    this.annualService = null // set to null because when click the button from quick reservation to start this.annualService is set from init
    
    if (futTechInsp.status.code === this.CODE_PAID) {
      this.router.navigate(['/add-edit-technical-inspection'], { queryParams: { id: futTechInsp.id }} );
    } else {
      this.isStartedTechInsp = true;
      this.showAllFields = false;
      this.isVisiblePaymentServicesTable = false;
      this.selectedAdditionalServices = [];
      this.mainTechInspForm.patchValue(futTechInsp);
      this.additionalTechInspForm.patchValue(futTechInsp);
      this.technicalInspection = futTechInsp;
      this.currentTechnicalInspectionTimes = futTechInsp.techInspectionTimes!;
      this.checkForContract(futTechInsp.regNumber);
      this.agreeWithNotification = this.agreeWithNotification === undefined || this.agreeWithNotification === null ? true : this.agreeWithNotification;
      this.additionalTechInspForm.get('isAgreeWithNotification')?.patchValue(this.agreeWithNotification);
      this.additionalTechInspForm.get('isEmployee')?.patchValue(false);
      this.techPointWorkingHourIds = futTechInsp.techPointWorkingHourIds;

      if (futTechInsp.regNumber != "") {
        this.getRegCertNumberIfExists(futTechInsp.regNumber);
      }
  
      this.techInspService.getRvsCategoryServicesByType(futTechInsp.rvsCategory, this.userTechnicalPointId).pipe(
        tap(([basicServices, additionalServices, annualServices]) => {
          this.basicServices = basicServices;
          this.additionalServices = additionalServices;
          this.annualServices = annualServices;
          
          this.basicServices.forEach(basicTechPointService => {
            this.technicalInspection.techInspectionServices?.forEach(techInspService => {
              if (basicTechPointService.id === techInspService.technicalPointService!.id) {
                this.mainTechInspForm.get('basicTechPointService')?.patchValue(basicTechPointService);
              }
            });
          });
  
          this.additionalServices.forEach(additionalTechPointService => {
            this.technicalInspection.techInspectionServices?.forEach(techInspService => {
                if(additionalTechPointService.id === techInspService.technicalPointService!.id) {
                  this.selectedAdditionalServices.push(additionalTechPointService);
                }
            });
          });
  
          this.annualServices.forEach(annualTechPointService => {
            this.technicalInspection.techInspectionServices?.forEach(techInspService => {
              if(annualTechPointService.id === techInspService.technicalPointService!.id) {
                this.annualService = annualTechPointService;
                this.customerSubscriptionName = annualTechPointService.serviceName!;
              }
            });
          });
         
          this.mainTechInspForm.get('lineNumber')?.patchValue(this.technicalInspection.techInspectionTimes![0].technicalPointWorkingHours!.line.number);
      })).subscribe();

      if (this.technicalInspection.techInspectionOrders?.length == 0) {
        this.isVisiblePaymentServicesTable = false;
      } else {
        this.technicalInspection.techInspectionOrders?.forEach(techInspOrder => {
          techInspOrder.order!.orderElements.forEach(orderElement => {
            this.paymentServiceConvertedData.push(createPaymentServiceData(orderElement.service.name!, orderElement.service.serviceType!.description, orderElement.totalAmount, orderElement.discountAmount == null ? 0 : orderElement.discountAmount));
          });
        });
      }

      if (this.technicalInspection.techInspectionOrders![0].order?.promotion?.id != null) {
        this.hasPromocode = true;
      }
    }
  }

  validateMainFormRegNumber(regNumber: string) {
    this.mainFormValidation = true;
    this.slotFormValidation = false;

    regNumber = this.validateRegNumber(regNumber, true);
    this.mainTechInspForm.get('regNumber')?.patchValue(regNumber);
  }

  validateAvailableSlotFormRegNumber(regNumber: string) {
    this.slotFormValidation = true;
    this.mainFormValidation = false;

    regNumber = this.validateRegNumber(regNumber, false);
    this.availableSlotForm.get('regNumber')?.patchValue(regNumber);
  }

  validateRegNumber(regNumber: string, isMainForm: boolean) {
    regNumber = regNumber.toUpperCase();
    const endWithNumbers = /\d+$/;

    this.isInvalidRegNumberForForeigner = false;
    this.isInvalidRegNumberForDiplomatic = false;
    this.isInvalidRegNumberForElectric = false
    
    let firstDiplomaticCheck = false;
    let secondDiplomaticCheck = false;
    let thirdDiplomaticSymbolsEndCheck = true;

    if (regNumber.length < 5) {
      if (isMainForm) {
        regNumber.match(config.regexRegNumber) || regNumber === "" ? this.isValidMainFormRegNum = false :  this.isValidMainFormRegNum = true;
        return regNumber;
      } 
      regNumber.match(config.regexRegNumber) || regNumber === "" ? this.isValidSlotFormRegNum = false :  this.isValidSlotFormRegNum = true;

      return regNumber;
    } else {
      firstDiplomaticCheck = regNumber.startsWith('C') || regNumber.startsWith('CC') || regNumber.startsWith('С') || regNumber.startsWith('СС');
      secondDiplomaticCheck = regNumber.charAt(1).startsWith('C') || regNumber.charAt(1).startsWith('С');
      if (regNumber.charAt(regNumber.length - 1).match(endWithNumbers)) {
        thirdDiplomaticSymbolsEndCheck = false;
      }
    }

    // foreign
    if (regNumber.startsWith('XH') || regNumber.startsWith('ХН')) {
      regNumber.match(config.regexForeigner) || regNumber === "" ? this.isInvalidRegNumberForForeigner = false :  this.isInvalidRegNumberForForeigner = true;
      if (regNumber.length > 6) {
        this.isInvalidRegNumberForForeigner = true;
      }
    
    // diplomatic
    } else if (firstDiplomaticCheck && secondDiplomaticCheck && !thirdDiplomaticSymbolsEndCheck) {
      this.isValidMainFormRegNum = false;
      regNumber.match(config.regexDiplomatic) || regNumber === "" ? this.isInvalidRegNumberForDiplomatic = false :  this.isInvalidRegNumberForDiplomatic = true;
    // electric 
    } else if (regNumber.startsWith('EA') || regNumber.startsWith('ЕА')) {
      regNumber.match(config.regexElectric) || regNumber === "" ? this.isInvalidRegNumberForElectric = false :  this.isInvalidRegNumberForElectric = true;
      
      if (regNumber.length > 8) {
        this.isInvalidRegNumberForElectric = true;
      }

      let isCorrectEnding = false;
      if (regNumber.endsWith('A')) {
        isCorrectEnding = true;
      } else if (regNumber.endsWith('А')) {
        isCorrectEnding = true;
      }
      if (!isCorrectEnding) {
        this.isInvalidRegNumberForElectric = true;
      }

    // regular
    } else {
      if (isMainForm) {
        regNumber.match(config.regexRegNumber) || regNumber === "" ? this.isValidMainFormRegNum = false :  this.isValidMainFormRegNum = true;
      } else {
        regNumber.match(config.regexRegNumber) || regNumber === "" ? this.isValidSlotFormRegNum = false :  this.isValidSlotFormRegNum = true;
      }
    }
    
    return regNumber;
  }

  // validateMainFormRegCertNumber(regCertNumber: string): void {
  //   regCertNumber = regCertNumber.toUpperCase();
  //   regCertNumber.match(config.regexOnlyLatinSymbolsAndDigits) || regCertNumber === "" ? this.isValidMainFormRegCertNum = false :  this.isValidMainFormRegCertNum = true;
  //   this.mainTechInspForm.get('registrationCertNumber')?.patchValue(regCertNumber);
  // }

  normalizeRegNumber(formType: string, regNumber: string) {
    this.techInspService.getNormalizedRegNumber(regNumber).subscribe(normalizedNumber => {
      formType === 'mainForm' ? this.mainTechInspForm.get('regNumber')?.patchValue(normalizedNumber.regNum) : this.availableSlotForm.get('regNumber')?.patchValue(normalizedNumber.regNum);
    });

    if (formType === 'mainForm') {
      this.checkForContract(regNumber);
      if (regNumber != "") {
        this.getRegCertNumberIfExists(regNumber);
      }
    }
  }

  getRegCertNumberIfExists(regNumber: string) {
    this.techInspService.getRegCertNumberIfExists(regNumber).subscribe(resp => {
      this.mainTechInspForm.get('registrationCertNumber')?.patchValue(resp.regCertNumber);
    });
  }

  refreshFutureTechnicalInspections() {
    this.refreshIntervalId = setInterval(() => {
      this.techInspService.getFutureTechnicalInspections(this.userTechnicalPointId).subscribe(futureTechInspections => {
        this.futureTechInspections = futureTechInspections;
        this.showHideLateAndStartButton(this.futureTechInspections);
        this.getGroupedTechnicalPointWorkingHours(this.availableSlotForm.value, this.arePastLoaded);
        this.futureTechInspections.forEach(techInsp => {
          techInsp.inspectionDate = this.prepareDateForUi(techInsp.inspectionDate);
        });
      });
    }, 1 * 60_000);
  }

  prepareNewTechnicialInpection() {
    this.mainTechInspForm.reset();
    this.additionalTechInspForm.reset();
    this.showAllFields = false;
    this.isStartedTechInsp = false;
    this.isVisiblePaymentServicesTable = false;
    this.disableTechPointServices = null;
    this.disableAnnualServiceAndDiscount = null;
    this.submittedMainForm = false;
    this.submittedAdditionalForm = false;
    this.paymentServiceTableData = [];
    this.paymentServiceConvertedData = [];
    this.techPointWorkingHourIds = [];
    this.technicalInspection = new TechnicalInspection;;
    this.mainTechInspForm.get('rvsCategory')?.patchValue(this.defRvsCategory);
    this.additionalTechInspForm.get('isAgreeWithNotification')?.patchValue(this.agreeWithNotification);
    this.additionalTechInspForm.get('isEmployee')?.patchValue(false);
    this.mainTechInspForm.get('technicalPointId')?.patchValue(this.userTechnicalPointId);
    this.customerSubscriptionName = null;
    this.techInspStatusName = null;
    this.techInspMobileId = null;
    this.annualService = null;
    this.customerSubscription = null;
    this.issueInvoice = false;
    
    this.techInspService.getRvsCategoryServicesByType(this.defRvsCategory, this.userTechnicalPointId).pipe(
      tap(([basicServices, additionalServices, annualServices]) => {
        this.basicServices = basicServices;
        this.additionalServices = additionalServices;
        this.annualServices = annualServices;
        this.mainTechInspForm.get('basicTechPointService')?.patchValue(this.basicServices.find(service => service.serviceName === this.BASIC_SERVICE_NAME) || null);
        this.annualService = annualServices[0];
        this.selectedAdditionalServices = [];
    })).subscribe();

  }

  private prepareDateForUi(date: any) {
    let splitted  = date.split("-");
    let dateYear  = splitted[0];
    let dateMonth = splitted[1];
    let dateDay   = splitted[2];
    
    if (dateMonth.length === 1) {
      dateMonth = '0'+ dateMonth;
    }
    if (dateDay.length === 1) {
      dateDay = '0'+ dateDay;
    }
    
    return dateDay + '-' + dateMonth + '-' + dateYear;
  }

  private prepareDateForDb(date: any) {
    if (date == null || date == undefined) {
      return null;
    }

    let splitted  = date.split("-");
    let dateYear  = splitted[2];
    let dateMonth = splitted[1];
    let dateDay   = splitted[0];
    
    if (dateMonth.length === 1) {
      dateMonth = '0' + dateMonth;
    }
    if (dateDay.length === 1) {
      dateDay = '0' + dateDay;
    }
    if (dateYear.length !== 4) {
      return date;
    }
    
    return dateYear + '-' + dateMonth + '-' + dateDay;
  }

  showHideLateAndStartButton(futureTechInsps: any) {
    this.showStartButton = false;
    this.showLateButton = false;

    if (futureTechInsps.length != 0) {
      let currentDate = new Date();
      let currentHour = currentDate.getHours();
      let currenMinute = currentDate.getMinutes();

      let firstAvailableSlotTimeFromHour: number = +futureTechInsps[0].slot!.split(":")[0];
      let firstAvailableSlotTimeFromMinute: number = +futureTechInsps[0].slot!.split(":")[1];
      let addedLateTime: number = +futureTechInsps[0].slot!.split(":")[1] + 5;
      let firstAvailableSlotTimeToHour: number = +futureTechInsps[0].slot!.split(":")[2];
      let firstAvailableSlotTimeToMinute: number = +futureTechInsps[0].slot!.split(":")[3];

      let timeFrom = firstAvailableSlotTimeFromHour + ':' + firstAvailableSlotTimeFromMinute;
      let timeTo = firstAvailableSlotTimeToHour + ':' + firstAvailableSlotTimeToMinute;
      let now = currentHour + ":" + currenMinute;
      let lateTime = firstAvailableSlotTimeFromHour + ':' + addedLateTime; 

      let [hours1, minutes1] = timeFrom.split(':');
      let totalSecondsTimeFrom: number = (+hours1) * 60 * 60 + (+minutes1);

      let [hours2, minutes2] = timeTo.split(':');
      let totalSecondsTimeTo: number = (+hours2) * 60 * 60 + (+minutes2);

      let [hours3, minutes3] = now.split(':');
      let totalSecondsNow: number = (+hours3) * 60 * 60 + (+minutes3);

      let [hours4, minutes4] = lateTime.split(':');
      let totalSecondsLated: number = (+hours4) * 60 * 60 + (+minutes4);

      let techInspId: number = +this.technicalInspectionId;
      if (techInspId === futureTechInsps[0].id) {
        this.isStartedTechInsp = true;
        this.showStartButton = false;
        this.showLateButton = false;
      } else {
        if(totalSecondsTimeFrom <= totalSecondsNow && totalSecondsNow <= totalSecondsTimeTo) {
          this.showStartButton = true;
          if (totalSecondsLated <= totalSecondsNow) {
            this.showLateButton = true;
          }
        }
      }
    }
  }

  customerDiscountFormatter(element: any): string {
    return element.discountName;
  }

  private applyLoyaltyCardDiscountForAllowedServices() {
    const value = this.mainTechInspForm.get('basicTechPointService')?.value;
    if (!value) {
      return false;
    }
    const mainServiceName = value.serviceName;
    if (!mainServiceName) {
      return false;
    }
    if (!this.allowedCustomerCardDiscountServices.includes(mainServiceName)) {
      return false;
    }

    return true;
  }

  async onChangeLoyaltyCard(event: any) {
    this.displayExceedCardMessage = false;
    this.displayCardDoesNotExistMessage = false;

    const canApply = this.applyLoyaltyCardDiscountForAllowedServices();
    if (!canApply) {
      this.displayExceedCardMessage = false;
      this.displayCardDoesNotExistMessage = false;
      this.clearSubject();
      return;
    }

    if (!event) {
      this.clearSubject();
      this.displayExceedCardMessage = false;
      this.displayCardDoesNotExistMessage = false;
      this.clearInvalidCard();
      return;
    }
    this.isVisiblePaymentServicesTable = false;
    this.paymentServiceConvertedData = [];
    this.paymentServiceTableData = [];

    const cardField = this.additionalTechInspForm.get('customerCard')?.value;
    if (!cardField) {
      this.additionalTechInspForm.get('customerCard')?.setValue('');
      this.clearSubject();
      this.clearInvalidCard();
      this.displayExceedCardMessage = false;
      this.displayCardDoesNotExistMessage = false;
      return;
    }

    await this.cardService.getLoyalComboByCard(cardField).toPromise().then(data => {
      if (data.messageOperation === 'Card usage exceeds maximum limit.') {
        this.displayCardDoesNotExistMessage = false;
        this.displayExceedCardMessage = true;
      }
      if (data.messageOperation === 'Card does not exist.') {
        this.displayExceedCardMessage = false;
        this.displayCardDoesNotExistMessage = true;
      }

      if (this.displayCardDoesNotExistMessage || this.displayExceedCardMessage) {
        this.customerCard = null;
        this.clearInvalidCard();
        this.clearSubject();
        return;
      }

      this.displayExceedCardMessage = false;
      this.displayCardDoesNotExistMessage = false;

      const loyalCombo = data as LoyalCombo;
      if (!loyalCombo.customerDiscount) {
        this.clearInvalidCard();
        this.clearSubject();
        console.error(`Card doesn't have a discount.`);
        this.customerCard = null;
        return;
      }
      this.lastSelectedDiscountByCard = loyalCombo.customerDiscount.discountName;
      this.customerDiscounts = [];
      this.customerDiscounts.push(loyalCombo.customerDiscount);

      this.additionalTechInspForm.get('customerDiscount')?.setValue(loyalCombo.customerDiscount);
      this.customerCard = loyalCombo.customerCard;
      
      if (loyalCombo.subjectVersion) {
        if (loyalCombo.subjectVersion?.mobileAppId) {
          this.subjectMobileAppId = loyalCombo.subjectVersion?.mobileAppId;
        }
        this.setSubjectData(loyalCombo.subjectVersion, true, true);
      } else {
        this.clearSubject();
      }
      this.calculateServicesDiscount();
    })
    .catch(err => {
      err.customMessage = `Card doesn't exist`; 
      displayError(err);
      this.clearInvalidCard();
      this.clearSubject();
    });
  }

  private clearInvalidCard() {
    this.customerDiscounts = this.initialCustomerDiscounts;
    this.customerCard = null;
    this.additionalForm.customerDiscount.setValue(0);
    this.additionalTechInspForm.get('customerDiscount')?.setValue('');
    this.lastSelectedDiscountByCard = this.INVALID_LOYAL_CARD;
  }

  private filterDiscountsByType(discounts: CustomerDiscount[]) {
    return discounts.filter((discount) => discount.customerDiscountType?.some((cdt) => cdt.discountType.code === this.DISCOUNT_TYPE_TECHNICAL_POINT));
  }

  private calculatePaidAmountServices() {
    this.currentAmountServices = 0;
    this.paymentServiceTableData.forEach(p => {
      if (p.amountDds !== undefined) {
        this.currentAmountServices += (p.amountDds - (p.calculatedDiscount || 0));
      }
    });
    this.currentAmountServices = parseFloat(this.currentAmountServices.toFixed(2));
    if (this.orderAmountPaid !== this.currentAmountServices) {
      this.orderPriceMismatch = true;
    } else {
      this.orderPriceMismatch = false;
    }
  }

  private calculatePaidAmountServicesFromTable() {
    if (this.orderAmountPaid !== this.currentAmountServices) {
      this.orderPriceMismatch = true;
    } else {
      this.orderPriceMismatch = false;
    }
  }

  hasTechInspectionOrder() {
    const result = 
      this.technicalInspection?.techInspectionOrders &&
      this.technicalInspection.techInspectionOrders.length > 0 &&
      this.technicalInspection.techInspectionOrders[0]?.order?.accountingDocument?.id && 
      this.technicalInspection.techInspectionOrders[0]?.order?.accountingDocument.id > 0;
      
    return result;
  }

  private onDataChangeModal() {
    const stored = localStorage.getItem('techInspModalPopUpCounter');
    this.techInspModalPopUpCounter = 0;
    if (stored) {
      this.techInspModalPopUpCounter = Number.parseInt(stored, 10);
    }
    if (this.techInspModalPopUpCounter > 0) {
      return;
    }
    this.dialogRef = this.dialog.open(this.dataChangeDialog, {
      width: '700px',
      height: '220px'
    });
    this.techInspModalPopUpCounter++;
    localStorage.setItem('techInspModalPopUpCounter', this.techInspModalPopUpCounter.toString());
  }

  onDataChangeConfirmationModal() {
    this.dialogRef.afterClosed().subscribe((result: any) => {
      if (result && this.lastTechInspId) {
        this.router.navigate(['/add-edit-technical-inspection'], { queryParams: { id: this.lastTechInspId }});
        this.lastTechInspId = 0;
      }
    });
  }

  private async findAlreadyStarted() {
    if (!this.technicalInspection?.mobileId) {
      return;
    } 

    const data = await this.techInspService.findLastIdByMobileAppId(this.technicalInspection.mobileId?.toString()).toPromise();
    if (!data) {
      this.lastTechInspId = 0;
      return;
    }

    this.lastTechInspId = data.id;
    const status = data.statusCode;
    if (this.technicalInspection.status) {
      const code = this.technicalInspection?.status.code;
      if ((code === 'STARTED' || code === 'TERMINATED' || code === 'RESERVED' || code === 'FINISHED') && code !== status) {
        this.onDataChangeModal();
        return;
      }
    }
  }
  
  // private applyPromotionFromSlot(techPointName: String, slotId: number): void {
  //   const mainServiceDiscounted = this.techInspectionMainForm.get('basicTechPointService')?.value;

  //   const techPoint = this.availableTimesTechPoints.find(attp => attp.technicalPointName === techPointName);
  //   if (!techPoint) {
  //       console.warn(`Technical point "${techPointName}" not found.`);
  //       return;
  //   }

  //   const slot = techPoint.slots.find(ts => ts.id === slotId);
  //   if (!slot) {
  //       console.warn(`Slot with ID "${slotId}" not found for technical point "${techPointName}".`);
  //       return;
  //   }

  //   const discountService = slot.services.find(s => s.name === mainServiceDiscounted?.name);
  //   if (discountService) {
  //       // this.findDiscount(discountService.name);
  //   } else {
  //       console.warn(`Discounted service "${mainServiceDiscounted?.name}" not found in slot "${slotId}".`);
  //   }
  // }

  // Get form
  get mainForm()       { return this.mainTechInspForm.controls; }
  
  get additionalForm() { return this.additionalTechInspForm.controls; }
  
  get availableForm()  { return this.availableSlotForm.controls; }

}